export function saveBlobAsDownload(blob: Blob | MediaSource, fileName = 'user-audio') {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = `${fileName}.mp3`;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
}
