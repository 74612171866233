// AppContext.js

import {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { StPredictionArgs } from "../lib/types";
import {
  createVideoWithSadTalker,
  getPending,
} from "../requests/fetch";
import { getUserAudio, getUserPhotos, requestImageXL } from '../requests/lagacy-api';
import {
  cancelVideoPrediction, getCompleted,
  getImgDescription, getPredictionData,
  getRecordingFromText
} from '../requests/lagacy-api';
import { TUserVoice } from "../lib/types";
import { saveBlobAsDownload } from "../lib/saveBlobAsDownload";
import { sleep } from "../lib/sleep";
import { useLocation } from "react-router-dom";

// @ts-ignore
const _PXL_APP_VARS = typeof PXL_APP_VARS !== "undefined" ? PXL_APP_VARS : {};

const checkForProgress = async (prediction, setResult) => {
  if (
    prediction &&
    prediction.status !== "succeeded" &&
    prediction.status !== "failed"
  ) {

    let _prediction = await getPredictionData(prediction.id);
    if (!_prediction) {
      setResult(_prediction?.error);
      return;
    }
    
    if (_prediction.status === 'failed') {
      setResult(_prediction?.error);
      return;
    }
    if (_prediction.status !== 'succeeded') {
      setResult(null, _prediction);
      await sleep(2000);
      await checkForProgress(_prediction, setResult)
    } else {
      setResult(null, _prediction);
    }
  }

}

type TNotificationData = {
  message: string;
  title: string;
  type?: "bg-success" | "bg-danger" ;
};

type TAppContext = {
  pendingVideos: any[];
  pendingImages: any[];
  completedVideos: any[];
  completedImages: any[];
  currentPrompt: {
    prompt: string;
    negative_prompt: string;
  };
  userPhotos: {
    url: string;
    name: string;
  }[];
  userCredits: number;
  prediction: any;
  error: any;
  payload: StPredictionArgs;
  userClonedVoices: TUserVoice[];
  selectedVoice: TUserVoice;
  userIsReady: boolean;
  notification: TNotificationData | null;
  isOpenModal: boolean;
  isLoading: boolean;
  handleLoading: (state: boolean) => Promise<void>;
  handleTextToRecording: (selectedVoiceId: TUserVoice, voiceText: string) => Promise<void>;
  handleNewVoice: (voiceId: string) => Promise<void>;
  handleSelectedVoice: (voiceId: string) => Promise<void>;
  handleCreateVideo: (e: any) => Promise<void>;
  handleCreateImage: (form: any) => Promise<void>;
  onNewMedia: (media: any) => Promise<void>;
  onNewPhoto: (media: any) => Promise<void>;
  onNewAudio: (url: string) => Promise<void>;
  onNewVideo: (videoUrl: { url: string, name: string }) => Promise<void>;
  getPhotos: () => Promise<void>;
  showNotification: (data: TNotificationData) => Promise<void>;
  cancelPrediction: (predictionId: any) => Promise<void>;
  handleMenu: (state: boolean) => void
  handleImg2Txt: (mediaUrl: string) => Promise<void>
  setUserCredits: (cretis: number) => Promise<void>
};

const AppContext = createContext<TAppContext | undefined>(undefined);

const AppProvider = ({ children }) => {
  // @todo: remove these, clean up this context
  const [prediction, setPrediction] = useState<any>(null);
  const [error, setError] = useState<any>(null);

  const [userClonedVoices, setUserClonedVoices] = useState<TUserVoice[]>([]);
  const [userCredits, setUserCredits] = useState<number>(0);
  const [userPhotos, setUserPhotos] = useState<{ url: string; name: string }[]>(
    []
  );
  const [selectedVoice, setSelectedVoiceId] = useState<TUserVoice>({
    voice_name: "Uploaded on " + new Date().toISOString(),
  });

  const [appState, setAppState] = useState({
    notification: null as TNotificationData | null,
    prediction: null,
    error: null,
    isOpenModal: false,
    completed: [],
    pending: [],
    isLoading: false,
    currentPrompt: {
      negative_prompt: '',
      prompt: ''
    } as { prompt: string; negative_prompt: string;} 
  })

  const [isReady, setIsReady] = useState(false)

  const location = useLocation();

  useEffect(() => {
    setAppState(prev => ({...prev, isOpenModal: false}))
  }, [location])



  const initPending = useCallback(async () => {
    let pending = await getPending();
    pending = pending.slice(5)
    setAppState(prev => ({ ...prev, pending}));
    if (pending.length > 0) {
      const initialRun = await getPredictionData(pending[0].id);
      setPrediction(initialRun)
      await checkForProgress(initialRun, (err, res) => {
        if (err) {
          setError(err)
        }
        if (res) {
          setPrediction(res)
          setIsReady(true)
        }
      })
    } else {
      setIsReady(true)
    }
  }, []);

  const initCompleted = useCallback(async () => {
    const completed = await getCompleted();
    setAppState(prev => ({...prev, completed}));

  }, []);


  const [payload, setPayload] = useState<StPredictionArgs>({
    driven_audio: null,
    ref_eyeblink: null,
    ref_pose: null,
    source_image: null,
  });

  const userIsReady = useMemo(() => {
    return isReady && userCredits > 0
  }, [isReady, userCredits])

  const getPhotos = async () => {
    const photos = await getUserPhotos();

    setUserPhotos(photos);
  };

  const handleCreateVideo = useCallback(
    async (e: any) => {
      e.preventDefault();

      let _prediction = await createVideoWithSadTalker(payload)

      setIsReady(false)

      setPrediction(_prediction);

      await checkForProgress(_prediction, (err, res) => {
        if (err) {
          setError(err)
        }
        if (res) {
          setPrediction(res)
          setIsReady(true)
        }
      })
    },
    [payload]
  );

  const handleCreateImage = useCallback(
    async (form: { 
      prompt: string;
      negative_prompt: string
    }) => {

      let _prediction = await requestImageXL(form)

      setIsReady(false)

      setPrediction(_prediction);

      await checkForProgress(_prediction, (err, res) => {
        if (err) {
          setError(err)
        }
        if (res) {
          setPrediction(res)
          setAppState(state => ({...state, currentPrompt: form}))
          setIsReady(true)
        }
      })
    },
    []
  );

  const handleImg2Txt = useCallback(async (imgUrl) => {
    const _prediction = await getImgDescription(imgUrl)

    setIsReady(false)

    if (!_prediction) {
      return
    }

    await checkForProgress(_prediction, (err, res) => {
      if (err) {
        setError(err)
      }
      if (res && res.output ) {
        // setPrediction(res)
        setAppState(state => ({...state, currentPrompt: {
          prompt: res.output,
          negative_prompt: ''
        }}))
        setIsReady(true)
      }
    })


  }, [])

  const handleNewVoice = useCallback(async (voiceId: string) => {
    const clonedVoices = await getUserAudio()
    setUserClonedVoices(clonedVoices)
    const selectedVoice = clonedVoices.find(voice => voice.voice_id === voiceId)
    if (selectedVoice) {
      setSelectedVoiceId(selectedVoice);
    }

  }, []);

  const handleSelectedVoice = useCallback(async (voiceId: string) => {

    const selectedVoice = userClonedVoices.find(voice => voice.voice_id === voiceId)
    if (selectedVoice) {
      setSelectedVoiceId(selectedVoice);
    }

  }, [userClonedVoices]);

  const handleTextToRecording = useCallback(async (selectedVoiceId, voiceText) => {
    const responseBlob = await getRecordingFromText({
      // @ts-ignore
      voiceId: selectedVoiceId.voice_id!,
      text: voiceText,
    });
    saveBlobAsDownload(
      responseBlob,
      `${_PXL_APP_VARS?.userId}-${selectedVoiceId.id}`
    );
  }, []);

  const onNewMedia = useCallback(
    async (media) => {
      setPayload({ ...payload, source_image: media.url });
    },
    [payload]
  );

  const onNewPhoto = useCallback(
    async (media: any) => {
      setPayload({ ...payload, source_image: media.url });
    },
    [payload]
  );

  const onNewAudio = useCallback(
    async (audio) => {
      setPayload({ ...payload, driven_audio: audio });
    },
    [payload]
  );

  const onNewVideo = useCallback(
    async (media: any) => {
      setPayload({ ...payload, ref_eyeblink: media.url })
    },
    [payload]
  );

  const cancelPrediction = useCallback(async (predictionId) => {
    await cancelVideoPrediction(predictionId)
    alert("cancelled ")
  }, [])


  const showNotification = useCallback(async (notification: TNotificationData) => {
    // Use the functional form of setAppState to ensure the latest state
    setAppState(prevState => ({
      ...prevState,
      notification
    }));
  
    // Use setTimeout with a callback function to handle state updates
    setTimeout(() => {
      setAppState(prevState => ({
        ...prevState,
        notification: null
      }));
    }, 3000); // Specify a minimal delay to ensure it's executed in the next tick
  }, [setAppState]); 

  const handleMenu = useCallback((isOpenModal: boolean) => {
    setAppState((prev) => ({...prev, isOpenModal}))
  },[])

  const handleLoading = useCallback( async (isLoading: boolean) => {
    setAppState((prev) => ({...prev, isLoading}))
  },[])

  const setCredits = useCallback(async (credits: number) => {
    setUserCredits(credits)
  }, [])

  const value = useMemo<TAppContext>(
    () => ({
      pendingVideos: appState.pending,
      completedImages: appState.completed,
      completedVideos: appState.completed,
      pendingImages: appState.pending,
      notification: appState.notification,
      isOpenModal: appState.isOpenModal,
      isLoading: appState.isLoading,
      currentPrompt: appState.currentPrompt,
      userPhotos,
      userCredits,
      error,
      prediction,
      payload,
      userClonedVoices,
      selectedVoice,
      userIsReady,
      setUserCredits: setCredits,
      handleSelectedVoice,
      onNewAudio,
      handleTextToRecording,
      handleNewVoice,
      handleCreateVideo,
      onNewMedia,
      onNewPhoto,
      onNewVideo,
      getPhotos,
      cancelPrediction,
      showNotification,
      handleMenu,
      handleLoading,
      handleCreateImage,
      handleImg2Txt
    }),
    [
      handleImg2Txt,
      handleCreateVideo,
      cancelPrediction,
      handleNewVoice,
      handleTextToRecording,
      onNewAudio,
      onNewMedia,
      onNewPhoto,
      onNewVideo,
      handleSelectedVoice,
      setCredits,
      selectedVoice,
      userCredits,
      userPhotos,
      error,
      prediction,
      payload,
      userClonedVoices,
      userIsReady,
      showNotification,
      handleMenu,
      handleLoading,
      handleCreateImage,
      appState.notification,
      appState.isOpenModal,
      appState.completed,
      appState.pending,
      appState.isLoading,
      appState.currentPrompt,
    ]
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within a AppProvider");
  }
  return context;
};

export default AppProvider;
