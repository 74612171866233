import { FaMinusCircle, FaPlusCircle } from "react-icons/fa"
import { MAX_RSS_URLS } from "../../lib/config"
import { useState } from "react"
import { useAgentStore } from "../../context/agent.store"
import { AgentTools } from "../../lib/types"


export const RssAgentFeed = ({toolName = AgentTools.RSSTOOL}) => {
  const [enteredUrls, setEnterUrls] = useState<string[]>([])
  const [inputUrl, setUrl] = useState("")

  const onEnterUrls = useAgentStore(state => state.addToolData)


  const removeUrl = (url) => {
    setEnterUrls(
      enteredUrls.filter(index => url !== index)
    )
  }

  const handleSaveUrl = (newUrlList) => {
    setEnterUrls(newUrlList)

    onEnterUrls({
      data: newUrlList,
      name: toolName
    })
  }

  return (
    <div className="bd-example mt-xs-3 mt-sm-3 mt-md-0">
      <div className="d-flex tool-titlebar">
        <span className="tool-logo">
          <img src="/icons/rss-button.png" alt="" />
        </span>
        <h4 className='title'>RSS Agent Tool</h4>
        <span className="tool-badge">
          <img src="/icons/sign.png" alt="" />
        </span>
      </div>
      <p className='sub-title'>Add up to {MAX_RSS_URLS} RSS urls to fetch content from. Your agent will fetch the data from these urls when you ask questions.</p>
      <div className="form">
        <div className="mb-3">
          {
            enteredUrls.map((url, i) => (
              <div key={i} className="d-flex flex-md-row">
                <label htmlFor="url-entered-rss" className="col-sm-1 form-label p-1">{i + 1}</label>
                <div className="col-sm-9">
                  <input type="text" readOnly className="form-control-plaintext" id={`url-entered-rss-${i}`} value={url} />
                </div>
                <div className="col-sm-1">
                  <button onClick={() => removeUrl(url)} className='btn btn-icon p-1'><FaMinusCircle size={40} /></button>
                </div>
              </div>

            ))
          }
        </div>
        {
          (MAX_RSS_URLS - enteredUrls.length) !== 0 && (
            <div className="mb-3">
              <label htmlFor="url-input-rss" className="form-label">RSS URL</label>
              <div className="row">
                <div className="col-sm-9">
                  <input value={inputUrl} onChange={(e) => setUrl(e.target.value)} placeholder='https://somefeed.com/rss' type="url" className="form-control" id="url-input-rss" aria-describedby="rss-url-field" />
                </div>
                <div className="col-sm-3">
                  <button className="btn btn-outline-primary" onClick={() => {
                    if (enteredUrls.includes(inputUrl)) {
                      setUrl("")
                      return
                    }
                    handleSaveUrl([...enteredUrls, inputUrl])
                    setUrl("")

                  }}>
                    <FaPlusCircle />&nbsp;
                    Add
                  </button>
                </div>
              </div>
              <div id="rss-url-field" className="form-text">You can add {MAX_RSS_URLS - enteredUrls.length} more urls.</div>
            </div>
          )
        }
      </div>
    </div>
  )
}