import { Helmet } from 'react-helmet';

export function AppHelmet({ siteTitle = '' }) {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Install the AI Assistant to get the best experience." />
        <title>{siteTitle}</title>
      </Helmet>
    </>
  );
}
