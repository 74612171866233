import axios from 'axios'
import { APP_VARS, WP_URL } from '../lib/config';
import { wpAxiosInstance } from './http/wpAxiosInstance';
import { sharpAxiosInstance } from './http/sharpAxiosInstance';
import { nodeAxiosInstance } from './http/nodeAxiosInstance';
import queryString from 'query-string';

export const createVideoWithSadTalker = async (payload) => {
  let body = payload;

  const formData = new FormData();
  Object.entries(body).forEach(([key, value]) => {
    // @ts-ignore
    formData.append(key, value);
  });
  formData.append("action", "pxl_v_cam_app_create_new_create_new_video_v1");
  formData.append("nonce", APP_VARS.nonce);

  const response = await wpAxiosInstance.post('/app', formData);
  if (response.status !== 200) {
    throw response.data
  }
  return response.data
}

export const getWpSessionUser = async () => {

  const response = await axios.post(`${WP_URL}/wp-json/pxl-store-net/v1/publicauth`);
  if (response.status !== 200) {
    throw response.data
  }
  return response.data
}

export const checkConnectionWithWp = async (formData: {sessionId?: string, siteUrl?: string, email?: string}) => {
  const qs = queryString.stringify({
    sessionId: formData.sessionId,
    site_url: formData.siteUrl,
    email: formData.email
  })
  const response = await nodeAxiosInstance.get(`/api/customer-access?${qs}`)
  return response.data
}

export const connectWithWp = async (formData, access = 'read') => {
  const response = await nodeAxiosInstance.post(`/api/customer-access/${access}`, formData)
  return response.data
}
export const registerWithWp = async (formData) => {
  return await axios.post( `${WP_URL}/wp-json/pxl-store-net/v1/register?ack=${import.meta.env.VITE_APP_AUTH_KEY}`, formData)
}

export const signinWithWp = async (formData) => {
  return await axios.post( `${WP_URL}/wp-json/pxl-store-net/v1/sendlogin`, formData)
}

export const signinWithStore = async (formData) => {
  return await axios.post( `${WP_URL}/wp-json/pxl-store-net/v1/sendlogin`, formData)
}

export const authWithStore = async (formData) => {
  return await axios.post( `${WP_URL}/wp-json/pxl-store-net/v1/auth`, formData)
}

export const getAuthUserWithWp = async (token: string) => {
  return await axios.get( `${WP_URL}/wp-json/pxl-store-net/v1/validate?hdx=${token}` )
}

export const uploadPhoto = async (file: File | Blob | any, url?, prompt?) => {
  // send upload request to wordpress using fetch and formdata
  const formData = new FormData();

  if (!url) {
    formData.set('file', file);
    const sharpRes = await sharpAxiosInstance.post(`/upload`, formData)
    if (sharpRes.data.url) {
      return sharpRes.data.url
    }
  }
  if (url) {
    formData.set('media_url', url);
    const sharpRes = await sharpAxiosInstance.post(`/save-image`, formData)
    if (sharpRes.data.url) {
      return sharpRes.data.url
    }
  }


};

export const uploadUserPhoto = async (file: File | Blob | any, contentType: string, url?, prompt?) => {
  // send upload request to wordpress using fetch and formdata
  const formData = new FormData();
  formData.set('action', 'pxl_v_cam_app_upload_new_media_v1');
  formData.set('nonce', APP_VARS.nonce);
  formData.set('media_label', 'selfie-media');
  formData.set('media_name', file.name);
  formData.set('media_prompt', JSON.stringify(prompt) );
  const media_url = await uploadPhoto(file, url);
  if (media_url) {
    formData.set('media_url', media_url);
  }
  const response = await wpAxiosInstance.post(`/app`, formData)

  if (response.data && response.data.url) {
    return { url: response.data.url };
  }

  throw new Error(response.data)

};



export const getUserCredit = async () => {

  try {
    const response = await nodeAxiosInstance.get('/api/public/userCredit');
    const data = response.data;
    return data;
  } catch (error) {
    // Handle errors here
    console.error('Error fetching user credit:', error);
    throw error;
  }
};

/**
 * Gets videos in progress from the db execution log.
 * @returns Videos progress from the DB
 */
export const getPending = async () => {
  const formData = new FormData();
  formData.append('action', 'pxl_v_cam_app_get_video_progress');
  formData.append('nonce', APP_VARS.nonce);

  try {
    const response = await wpAxiosInstance.post(`/public`, formData);

    if (response.status !== 200) {
      // Handle non-200 status here if needed
      return;
    }

    const data = response.data;
    return data.slice(5);
  } catch (error) {
    // Handle errors here
    console.error('Error fetching pending videos:', error);
    throw error;
  }
};


