import { APP_VARS } from '../lib/config';
import { wpAxiosInstance } from './http/wpAxiosInstance';


export const uploadUserAudio = async (file: File, path = "voice-notes", url = undefined) => {
  const formData = new FormData();
  formData.append('action', 'pxl_v_cam_app_upload_new_media_v1');
  formData.append('file', file);
  formData.append('nonce', APP_VARS.nonce);
  formData.append('media_label', 'voice-recording');
  formData.append('media_name', 'audio-file.mp3');
  url && formData.append('media_url', url);

  const response = await wpAxiosInstance.post(`/app`, formData);

  if (response.data && response.data.url) {
    return { url: response.data.url };
  }

  throw new Error(response.data);
};

export const uploadUserVideo = async (file: File, contentType: string, url = undefined) => {
  // send upload request to wordpress using fetch and formdata
  const formData = new FormData();
  formData.append('action', 'pxl_v_cam_app_upload_new_media_v1');
  formData.append('file', file);
  formData.append('nonce', APP_VARS.nonce);
  formData.append('media_label', 'eye-blink-video');
  formData.append('media_name', file.name);
  url && formData.append('media_url', url);

  const response = await wpAxiosInstance.post(`/app`, formData)

  return { url: response.data.url };
};

export const getUserPhotos = async () => {

  // get photos from wordpress
  const formData = new FormData();
  formData.append('action', 'pxl_v_cam_app_fetch_user_media_v1');
  formData.append('nonce', APP_VARS.nonce);

  const response = await wpAxiosInstance.post('/app', formData)

  let data = response.data
  data = data.filter(item => item.media_label === 'selfie-media').map((item) => {
    return {
      url: item.media_url,
      timeCreated: item.created_at,
      name: item.media_name
    }
  })

  return data;

}

export const getUserEyeBlinkVideos = async () => {
  // get photos from wordpress
  const formData = new FormData();
  formData.append('action', 'pxl_v_cam_app_fetch_user_media_v1');
  formData.append('nonce', APP_VARS.nonce);

  const reponse = await fetch(APP_VARS.ajaxUrl, {
    method: 'POST',
    body: formData,
  })

  let data = await reponse.json()
  data = data.filter(item => item.media_label === 'eye-blink-video').map((item) => {
    return {
      url: item.media_url,
      timeCreated: item.created_at,
      name: item.media_name
    }
  })

  return data;

}

export const getUserAudio = async (path = "voice-notes") => {
  const formData = new FormData();
  formData.append('action', 'pxl_v_cam_app_get_user_custom_voices_v1');
  formData.append('nonce', APP_VARS.nonce);

  const response = await wpAxiosInstance.post(`/app`, formData)

  return response.data;

};
export const postUserMedia = async (postMedia) => {
  const formData = new FormData();
  formData.append('action', 'pxl_v_cam_app_post_public_media');
  formData.append('title', postMedia.title);
  formData.append('content', postMedia.content);
  formData.append('media_url', postMedia.media_url);
  formData.append('nonce', APP_VARS.nonce);

  try {
    const response = await wpAxiosInstance.post('/app', formData);
    const data = response.data;
    return data;
  } catch (error) {
    // Handle errors here
    console.error('Error posting user media:', error);
    throw error;
  }
};export const getPredictionImageXLData = async (prediction_id) => {
  const loopFormData = new FormData();
  loopFormData.append("action", "pxl_v_cam_app_get_imagexl_prediction_progress");
  loopFormData.append("prediction_id", prediction_id);
  loopFormData.append("nonce", APP_VARS.nonce);

  try {
    const response = await wpAxiosInstance.post('/app', loopFormData);

    if (response.status !== 200) {
      // Handle non-200 status here if needed
      return;
    }

    const data = response.data;
    return data;
  } catch (error) {
    // Handle errors here
    console.error('Error fetching prediction imagexl data:', error);
    throw error;
  }
};

export const getPredictionData = async (prediction_id) => {
  const loopFormData = new FormData();
  loopFormData.append("action", "pxl_v_cam_app_get_video_prediction_progress");
  loopFormData.append("prediction_id", prediction_id);
  loopFormData.append("nonce", APP_VARS.nonce);

  try {
    const response = await wpAxiosInstance.post('/app', loopFormData);

    if (response.status !== 200) {
      // Handle non-200 status here if needed
      return;
    }

    const data = response.data;
    return data;
  } catch (error) {
    // Handle errors here
    console.error('Error fetching prediction video data:', error);
    throw error;
  }
};

export const getCompleted = async () => {
  const formData = new FormData();
  formData.append('action', 'pxl_v_cam_app_get_video_completed');
  formData.append('nonce', APP_VARS.nonce);

  const response = await wpAxiosInstance.post('/public', formData);

  return response.data;

};

export const getCompletedImages = async () => {
  const formData = new FormData();
  formData.append('action', 'pxl_v_cam_app_get_images_completed');
  formData.append('nonce', APP_VARS.nonce);

  const response = await wpAxiosInstance.post('/public', formData);

  return response.data;

};

export const cancelVideoPrediction = async (prediction_id) => {
  const formData = new FormData();
  formData.append('action', 'pxl_v_cam_app_cancel_video_prediction');
  formData.append('nonce', APP_VARS.nonce);
  formData.append('prediction_id', prediction_id);

  const reponse = await fetch(APP_VARS.ajaxUrl, {
    method: 'POST',
    body: formData,
  });

  let data = await reponse.json();
  return data;

};

export const getImgDescription = async (mediaUrl) => {
  const formData = new FormData();
  formData.append('action', 'pxl_v_cam_app_get_img_description');
  formData.append('nonce', APP_VARS.nonce);
  formData.append('media_url', mediaUrl);

  const response = await wpAxiosInstance.post(`/app`, formData);

  if (response.data) {
    return response.data;
  }

  throw new Error(response.data);
};

export const getRecordingFromText = async (audioForm: { voiceId: string; text: string; }) => {
  const formData = new FormData();
  formData.append('action', 'pxl_v_cam_app_text_to_custom_voice_v1');
  formData.append('nonce', APP_VARS.nonce);
  formData.append('voice_id', audioForm.voiceId);
  formData.append('text', audioForm.text);

  const response = await fetch(APP_VARS.ajaxUrl, {
    method: "POST",
    body: formData,
  }).catch((err) => {
    console.log(err);
  });
  if (!response) return;

  if (response.status === 200) {
    let voice = await response.blob();
    if (voice) {
      return voice;
    }
  } else {
    const error = await response.json();
    console.log(error);
    return error;
  }
};

export const cloneCustomVoice = async (audioForm: {
  name: string;
  audio_file_url: string;
  description: string;
}) => {
  const formData = new FormData();
  formData.append('action', 'pxl_v_cam_app_create_custom_voice_v1');
  formData.append('nonce', APP_VARS.nonce);
  formData.append('name', audioForm.name);
  formData.append('audio_file_url', audioForm.audio_file_url);
  formData.append('description', audioForm.description);

  const response = await fetch(APP_VARS.ajaxUrl, {
    method: "POST",
    body: formData,
  }).catch((err) => {
    console.log(err);
  });
  if (!response) return;

  if (response.status === 200) {
    let voice = await response.json();
    if (voice) {
      return voice;
    }
  } else {
    const error = await response.json();
    console.log(error);
    return error;
  }
};
export const uploadVideoSegment = async (file: Blob) => {
  const formData = new FormData();
  formData.append('action', 'pxl_v_cam_app_upload_new_media_v1');
  formData.append('file', file);
  formData.append('nonce', APP_VARS.nonce);
  formData.append('media_label', 'video-recording');
  formData.append('media_name', 'video.mp4');

  const reponse = await fetch(APP_VARS.ajaxUrl, {
    method: 'POST',
    body: formData,
  });

  const data = await reponse.json();

  return { url: data.url };
};
export const requestImageXL = async (prompt: any) => {
  const formData = new FormData();
  formData.append('action', 'pxl_v_cam_app_create_new_image_xl_v1');
  formData.append('prompt', prompt.prompt);
  formData.append('nonce', APP_VARS.nonce);
  if (prompt.negative_prompt && prompt.negative_prompt !== '') {
    formData.set('negative_prompt', prompt.negative_prompt);
  } else {
    formData.set('negative_prompt', `out of frame, lowres, text, error, cropped, worst quality, low quality, jpeg artifacts, ugly, duplicate, morbid, mutilated, out of frame, extra fingers, mutated hands, poorly drawn hands, poorly drawn face, mutation, deformed, blurry, bad anatomy, bad proportions, extra limbs, cloned face, disfigured, gross proportions, malformed limbs, missing arms, missing legs, extra arms, extra legs, fused fingers, too many fingers, long neck, username, watermark, signature.`);
  }

  const response = await wpAxiosInstance.post(`/app`, formData);

  if (response.status !== 200) {
    throw response.data;
  }
  return response.data;

};

