/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { fetchSessionAgents } from '../../requests/graphql/session'
import { useThreadStore } from '../../context/thread.store'
import { useAgentStore } from '../../context/agent.store'
import Thread from './Thread'
import { useSessionStore } from '../../context/session.store'

function Inbox() {
  const { sessionId } = useParams()
  const reload = useThreadStore(state => state.reloadSessionMessages)
  const loadCustomAgents = useAgentStore(state => state.fetchCustomAgents)
  const setSessionId = useSessionStore(state => state.setSessionId)
  const getSessionAgents = useThreadStore(state => state.getSessionAgents)
  const reset = useThreadStore(state => state.resetSessionMessages)


  useEffect(() => {
    if (!sessionId || sessionId === "") {
      return
    }

    setSessionId(sessionId)
    reset()

    // should use the thread id to get the messages
    reload({
      after: "",
      sessionId: sessionId!,
    })

    getSessionAgents(sessionId!)

    
    loadCustomAgents()

  }, [sessionId])
  
  return <Thread  />
}

export default Inbox