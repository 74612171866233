import React, { useState } from 'react'
import { FaStop } from 'react-icons/fa6'
import { WP_URL } from '../lib/config'
import { useSignIn } from 'react-auth-kit'
import { getAuthUserWithWp, signinWithStore, signinWithWp } from '../requests/fetch'
import { MdOutlineCircle } from "react-icons/md";
import { Link } from 'react-router-dom'
import { useCustomerStore } from '../hooks/useCustomerStore'



function StoreLogin() {
  const signIn = useSignIn()
  const {isAppTohjuDomain} = useCustomerStore()

  const [form, setForm] = useState({
    username: "",
  })
  const [loginError, setLoginError] = useState(null)
  const [loginSuccess, setLoginSuccess] = useState<string | null>(null)
  const [isLoading, setLoading] = useState(false)

  const handleSubmit = async (e) => {
    setLoading(true)
    setLoginError(null)
    e.preventDefault()
    const tokenResponse = await signinWithStore(form).catch(err => {
      setLoginError(err.response?.data?.data?.message || err.message)
      setLoading(false)
    })
    if (!tokenResponse) return

    setLoginSuccess("A one-time link has been sent to your email. Please check your email to continue.")

    setLoading(false)

  }


  return (
    <>
      <div id="appCapsule">
        <div className="row">
          <div className="col-sm-12 col-md-3 mx-auto">
            <div className="section mt-2 text-center">
              <h1>Log in</h1>
              <h4>Fill the form to log in</h4>
            </div>
            <div className="section mb-5 p-2">
              {
                loginSuccess && (
                  <div className="d-flex justify-content-center mt-2">
                    <div className="alert alert-success mb-1" role="alert">
                      {loginSuccess}
                    </div>
                  </div>
                )
              }
              {
                !loginSuccess && (
                  <form onSubmit={handleSubmit}>
                    <div className="card">
                      <div className="card-body pb-1">
                        <div className="form-group basic">
                          <div className="input-wrapper">
                            <label className="label" htmlFor="email1">
                              Store Username/Email
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="email1"
                              placeholder="Your username or email"
                              value={form.username}
                              onChange={e => setForm({ ...form, username: e.target.value })}
                            />
                            <i className="clear-input">
                              <FaStop />
                            </i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-links mt-2">
                      <div>
                        <Link to="/" >Use as a Guest</Link>
                      </div>
                      {
                        isAppTohjuDomain() && (

                          <div>
                            <Link to="/connect-store/customer" >Connect Store</Link>
                          </div>
                        )
                      }
                    </div>
                    {
                      loginError && (
                        <div className="d-flex justify-content-center mt-2">
                          <div className="alert alert-danger mb-1" role="alert">
                            {loginError || 'Login attempt failed. Please try again.'}
                          </div>
                        </div>
                      )
                    }
                    <div className="form-button mt-3  transparent">
                      <button disabled={isLoading} type="submit" className="btn btn-primary btn-block btn-lg">
                        Log in &nbsp;
                        {
                          isLoading && (

                            <span className='animate__animated animate__heartBeat animate__infinite'>
                              <MdOutlineCircle />
                            </span>
                          )
                        }
                      </button>
                    </div>
                  </form>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </>

  )
}

export default StoreLogin