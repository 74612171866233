/* eslint-disable no-restricted-globals */

import { useAppContext } from '../context/app.context';
import AppHeader from '../components/AppHeader';
import AppNotification from '../components/Notification';
import Sidebar from '../components/Sidebar';
import { Outlet, useBeforeUnload } from 'react-router-dom';
import { useCallback } from 'react';
import { AppHelmet } from '../components/AppHelmet';
import { useSessionStore } from '../context/session.store';
import QuickTodo from '../components/session/QuickTodo';


export default function HomePage() {
  const isMd = window.innerWidth > 768

  const { isOpenModal, handleMenu } = useAppContext();

  useBeforeUnload(
    useCallback(() => {
      handleMenu(false)
    }, [handleMenu])
  );

  return (
    <div >
      <AppHelmet siteTitle={`Your Agency: Tohju.com, AI Freelancing with Micropayments `} />
      <div>
        <AppNotification />
        <AppHeader />
        <div className="main-container container mx-auto md:px-6">
          <Outlet />
        </div>
        {
          isMd && (
            <div className="session-quick-todo is-md">
              <QuickTodo />
            </div>
          )
        }
      </div>
      <Sidebar /> 
    </div>
  )


}