/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react'
import { useThreadStore } from '../../context/thread.store'
import { TAppUser, TMessageSelectedLLM } from "../../lib/types"
import useSocket from '../../hooks/useSocket'
import { useAuthUser } from 'react-auth-kit'
import { FaArrowRight, FaCircle } from 'react-icons/fa6'
import { uploadUserDoc } from '../../requests/message-req'
import { useAppContext } from '../../context/app.context'
import PostEditor from '../textarea/TextArea'
import { extractIdsAndTexts, findAgentFromContent } from '../../lib/utils'
import { useAgentStore } from '../../context/agent.store'
import { MdUploadFile } from 'react-icons/md'
import { IoPersonAddOutline } from 'react-icons/io5'
import { useSessionStore } from '../../context/session.store'
import { GoDotFill } from 'react-icons/go'
import { TbArrowsDiagonalMinimize2 } from "react-icons/tb";
import { LiaExpandSolid, LiaWindowMinimize } from "react-icons/lia";
import SelectAgent from './SelectAgent'


function ThreadFooter({ isShared }) {

  const [isExpanded, setExpand] = useState(false)
  const [selectedLLM, setSelectedLLM] = useState<TMessageSelectedLLM | null>(null)

  const sessionId = useSessionStore(state => state.sessionId)
  const sendUserToAgentMessage = useThreadStore(state => state.sendMessageUserToAgent)
  const setShowingAgents = useThreadStore(state => state.setShowingAgents)
  const sendMessageToSession = useThreadStore(state => state.sendMessageUserToSession)
  const setLoading = useThreadStore(state => state.setLoading)
  const [message, setMessage] = React.useState("")
  const user = useAuthUser()
  const authUser = user && user() as TAppUser
  const { showNotification } = useAppContext()
  const getMessage = useThreadStore(state => state.getSessionMessages)
  const sessionAgents = useThreadStore(state => state.sessionAgents)
  const customAgents = useAgentStore(state => state.agents)
  const selectedAgents = useAgentStore(state => state.selectedAgents)
  const setSelectedAgents = useAgentStore(state => state.addSelectedAgent)
  const isShowingAgents = useThreadStore(state => state.isShowingAgents)
  const resetSelectedAgents = useAgentStore(state => state.resetSelectedAgents)
  const availableLLMs = useAgentStore(state => state.availableLLMs)
  const getAvailableLLMs = useAgentStore(state => state.getAvailableLLMs)

  const webSocketClient = useSocket()


  useEffect(() => {
    getAvailableLLMs()
  }, [])


  useEffect(() => {
    if (!sessionId) return

    // webSocketClient.users
    if (!authUser?.ID || !sessionId) return
    webSocketClient.logIn({ sessionId, userId: authUser.ID })

  }, [authUser, sessionId, webSocketClient])


  const handleSendMessage = async () => {
    // select agent from message
    setLoading(true)
    // const hasAgents = extractIdsAndTexts(message).length > 0
    if (selectedAgents.length > 0) {
      // await sendMessageToSession({content: message}, sessionId!)
      await sendUserToAgentMessage({ content: message }, sessionId!, selectedAgents[0], undefined,  selectedLLM)
    } else {
      const agent = findAgentFromContent(message, sessionAgents)
      await sendUserToAgentMessage({ content: message }, sessionId!, agent!, undefined,  selectedLLM)
    }

    setMessage("")
    setLoading(false)
  }

  const handleFileUpload = useCallback(async (e) => {
    if (!sessionId) return
    // const file = e.target.files[0] as File;
    setLoading(true)
    const data = await uploadUserDoc(e.target.files, sessionId)
      .catch(err => {
        console.log(err)
        showNotification({
          title: 'Operation Failed',
          message: 'Document upload failed. Maximum pdf size is 6MB',
          type: 'bg-danger'
        });
      });
    if (!data) return
    setLoading(false)
    const count = data && data.length > 0 ? data.length : 1
    showNotification({
      title: 'Upload Success',
      message: `${count} documents Successfully Added`,
      type: 'bg-success'
    });

    e.target.value = null

  }, [
    authUser,
    getMessage,
    sessionId,
    setLoading,
    showNotification,
    webSocketClient
  ])

  const handleSelectFile = e => {
    e.preventDefault()
    document.getElementById('file_input')?.click()
  }

  const handleSelectAgent = (agent) => {
    if (selectedAgents.find(_agent => agent.id === _agent.id)) {
      resetSelectedAgents()
    } else {
      setSelectedAgents(agent)
    }
  }


  return (

    <>
      {
        isShowingAgents && (
          <SelectAgent
            onClose={() => setShowingAgents(false)}
            cards={[...[...customAgents].reverse()]}
            handleClick={handleSelectAgent} 
          />
        )
      }
      <div className="chatFooter">
        <form>
          <div className="d-flex flex-column w-100">
            <div className="form-group basic">
              <PostEditor
                data={[].map(({ id, name }) => ({ id, display: name }))}
                value={message}
                onChange={e => setMessage(e.target.value)}
                isExpanded={isExpanded}
              />

            </div>
            <div className="form-group d-flex flex-row justify-content-between">

              <input onChange={handleFileUpload} className="block form-control" multiple accept='text/plain,application/zip,application/pdf,.pdf' id="file_input" type="file" style={{ "display": "none" }} />
              <div className="left-toolsbar">
                <button onClick={handleSelectFile} className="btn btn-icon btn-text-secondary rounded btn-lg p-1">
                  <MdUploadFile size={100} />
                </button>
                {
                  !isShared && (
                    <button onClick={(e) => (e.preventDefault(), setShowingAgents(!isShowingAgents))} className="btn btn-outline-secondary px-2 py-1 ms-1 me-2" style={{ maxWidth: '170px' }} >
                      {
                        selectedAgents.length !== 0 && (
                          <span className='my-auto' >
                            <GoDotFill color='green' />&nbsp;
                          </span>
                        )
                      }
                      <IoPersonAddOutline size={20} />&nbsp;
                      <span className="d-none d-md-block">
                        {selectedAgents.length !== 0 ? `${selectedAgents.length} agents` : 'Add Agent'}
                      </span>
                    </button>
                  )
                }
              </div>
              <div className="right-toolsbar">
                <div className="btn-group d-flex gap-2 flex-rows">
                  <select
                    value={selectedLLM?.name}
                    onChange={e => {
                      const llm = availableLLMs.find(availableLlm => availableLlm.name === e.target.value )
                      if (llm) {
                        setSelectedLLM(llm)
                      }
                    }} 
                    name="" id="" className="form-control">
                    <option value="">Select LLM</option>
                    {
                      availableLLMs.map((llm, key) => (
                        <option value={llm.name} key={key}>{llm.name}</option>
                      ))
                    }
                  </select>
                  {
                    isExpanded ? (
                      <button type="button" className="btn btn-icon btn-primary-outline p-1 rounded " onClick={() => setExpand(false)}>
                        <LiaWindowMinimize />
                      </button>
                    ) : (

                      <button type="button" className="btn btn-icon btn-primary-outline p-1 rounded" onClick={() => setExpand(true)}>
                        <LiaExpandSolid />
                      </button>
                    )
                  }
                  <button disabled={message === ''} type="button" className="btn btn-primary rounded" onClick={() => handleSendMessage()}>
                    Send&nbsp;<FaArrowRight />
                  </button>
                </div>
              </div>


            </div>

          </div>
        </form>
      </div>
    </>

  )
}

export default ThreadFooter