import axios from 'axios';
import { NODE_API_URL, WP_URL } from '../../lib/config';
import { authHeader } from '../../lib/utils';

export const nodeAxiosInstance = axios.create({
  baseURL: NODE_API_URL,
});
nodeAxiosInstance.interceptors.request.use(
  async (config) => {
    const authToken = authHeader();
    if (authToken && authToken !== '') {
      config.headers.Authorization = `Bearer ${authToken}`;
      // config.headers["X-Store-Host"] = `${WP_URL}`;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  });
nodeAxiosInstance.interceptors.response.use(
  response => {
    // Check if the 'statusCode' property exists in the response data
    if (response.data && response.data.statusCode === 403) {
      // If 'statusCode' is 403, set the request status to 403
      return Promise.reject({
        response: {
          status: 403,
          data: response.data,
        },
      });
    }
    // If 'statusCode' is not 403, return the response as is
    return response;
  },
  error => {
    // Handle other errors here
    return Promise.reject(error);
  }
);
