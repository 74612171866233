import React, { useEffect, useState } from 'react'
import { useAuthUser, useAuthHeader } from 'react-auth-kit'
import { useAppContext } from '../context/app.context'

import { AuthdSidebar } from './AuthdSidebar'
import { getUserCredit } from '../requests/fetch'
import ModalBackdrop from './ModalBackdrop'

function Sidebar() {

  const authUser = useAuthUser()
  const authHeader = useAuthHeader()
  const { isOpenModal, handleMenu, handleLoading, showNotification, setUserCredits } = useAppContext()
  const [credits, setCredits] = useState<{
    credits: number;
    earnings: {
      kin: number;
    };
  }|null>(null)

  const user = authUser()
  const header = authHeader()

  const token = header.replace('Bearer ', '')

  useEffect(() => {
    handleLoading(true)
    getUserCredit()
      .then(async ({credits, earnings}) => {
        setCredits({credits, earnings})
        setUserCredits(credits);
        // enable or disable create button
        handleLoading(false)
        // return await getPhotos();

      })
      .catch((err) => {
        showNotification({
          message: err.message,
          title: 'App failed to start',
          type: 'bg-danger'
        })
      })
  }, [])

  return (

    <>
      {
        isOpenModal && (
          <>
            <div
              className={`modal fade panelbox panelbox-left ${isOpenModal ? 'show' : ''}`}
              id="sidebarPanel"
              style={{ display: "block" }}
            >
              {
                user && credits && (
                  <AuthdSidebar user={user} handleMenu={handleMenu} userCredits={credits} token={token} />
                )
              }
            </div>
            <ModalBackdrop handleClick={() => handleMenu(false)} />
          </>
        )
      }
    </>


  )
}

export default Sidebar


