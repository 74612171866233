import React from 'react'

function ConfirmDialog({message, onConfirm, onCancel}) {
  return (
    <>
      <div className="modal fade d-block show" id="confirmDialog" tabIndex={-1} aria-labelledby="confirmDialogLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header bg-danger text-white">
              <h5 className="modal-title" id="confirmDialogLabel">Confirm</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {
                !message && <p>Are you sure you want to delete this record?</p>
              }
            </div>
            <div className="modal-footer">
              <button onClick={onCancel} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button onClick={onConfirm} type="button" className="btn btn-danger">Delete</button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop"></div>
    </>
  )
}

export default ConfirmDialog