/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import { useCallback, useEffect, useRef, useState } from 'react'
import { Link, useBeforeUnload, useNavigate } from 'react-router-dom'

import { useSessionStore } from '../../context/session.store'
import { SessionItem } from './SessionItem'
import NoHistory from './NoHistory'
import { HiOutlineViewGridAdd, } from "react-icons/hi";
import { RiChatNewLine } from "react-icons/ri";
import Share from './Share';
import ConfirmDialog from '../ConfirmDialog';
import QuickTodo from './QuickTodo'
import { useAppContext } from '../../context/app.context'
import { WP_URL } from '../../lib/config'
import { useAuthHeader } from 'react-auth-kit'
import { FaMoneyCheckDollar } from 'react-icons/fa6'

function Sessions() {
  const [parentSize, setParentSize] = useState({ width: 0, height: 0 });
  const [isDeleting, setIsDeleting] = useState<number | null>(null)
  const parentRef = useRef(null);
  const isMd = window.innerWidth > 768

  const navigate = useNavigate()
  const { userCredits } = useAppContext()
  const authHeader = useAuthHeader()

  const header = authHeader()

  const token = header.replace('Bearer ', '')

  const createNewSession = useSessionStore(state => state.createNewSession)
  const userSessions = useSessionStore(state => state.userSessions)
  const sharedSessions = useSessionStore(state => state.sharedSessions)
  const loadSessions = useSessionStore(state => state.loadSessions)
  const deleteSession = useSessionStore(state => state.deleteSession)
  const showShare = useSessionStore(state => state.showShare)
  const setQuickAction = useSessionStore(state => state.setQuickAction)
  const isSharing = useSessionStore(state => state.isSharing)
  const [toggleDropdown, setToggleDropdown] = useState(false)
  const [showSession, setShowSession] = useState<"mine" | "shared">("mine")

  const sessions = showSession === "mine" ? userSessions : sharedSessions

  const startConversation = async (e) => {
    e.preventDefault()
    const session = await createNewSession()
    // redirect to new page 
    if (!session) return
    navigate(`/chat/thread/${session.id}`, {
      state: session
    })
  }

  const handleShowDeleteDialog = (sessionId) => async () => {
    setIsDeleting(sessionId)
  }

  const handleSessionDelete = (sessionId) => async (e?) => {
    e && e.preventDefault()
    await deleteSession(sessionId)
    await loadSessions()
  }

  const handleSessionForward = (sessionId) => async (e) => {
    //
  }

  const handleSessionShare = (sessionId) => async (e) => {
    //
    showShare(sessionId)
  }

  useEffect(() => {
    loadSessions()
  }, [])

  const handleToggleDropdown = (val: "mine" | "shared") => {
    setShowSession(val)
    setToggleDropdown(!toggleDropdown)
  }


  useEffect(() => {
    const getParentSize = () => {
      if (parentRef.current) {
        const { clientWidth, clientHeight } = parentRef.current;
        setParentSize({ width: clientWidth, height: clientHeight });
      }
    };

    getParentSize(); // Get initial size

    // Recalculate size when window is resized
    const handleResize = () => {
      getParentSize();
    };

    window.addEventListener('resize', handleResize);

    // Clean up event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [parentRef]); // Run effect only once on component mount


  useEffect(() => {
    setQuickAction(false)
    return () =>
      setQuickAction(true)
  }, [])

  const topUpCredits = () => {
    window.open(`${WP_URL}/wp-json/pxl-store-net/v1/validate?j0t=${token}&redirectUrl=${WP_URL}/shop` )
  }


  return (
    <>

      <div className="row">
        <div className="col-md-12">
          <div className="main-mail-container gap-4 mb-5 d-lg-flex">

            <div className="total-mails mt-5 mt-lg-5">

              <div className="p-3 d-flex flex-sm-row align-items-center border-bottom sessions-header">

                <div className="">

                  <input
                    className="d-none form-check-input"
                    type="checkbox"
                    id="checkAll"
                    defaultValue=""
                    aria-label="..."
                  />

                  <div className="dropdown">
                    <button
                      className="btn btn-outline-dark dropdown-toggle"
                      type="button"
                      id="sessionmenubtn"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      onClick={() => setToggleDropdown(!toggleDropdown)}
                    >
                      {showSession === "mine" ? "Your Sessions" : "Shared to you"}&nbsp;
                    </button>
                    {
                      toggleDropdown && (
                        <ul className="dropdown-menu d-block" aria-labelledby="sessionmenubtn">
                          <li>
                            <button className="dropdown-item" onClick={() => handleToggleDropdown("mine")}>
                              Your Sessions
                            </button>
                          </li>
                          <li>
                            <button className="dropdown-item" onClick={() => handleToggleDropdown("shared")}>
                              Shared to you
                            </button>
                          </li>
                        </ul>
                      )
                    }
                  </div>

                </div>
                <div className="unstyled d-flex flex-column flex-md-row justify-content-end ms-auto mb-0 ps-md-3 gap-1">
                  {
                    Number(userCredits) > 0 ? (
                      <>                      
                        <Link to={"/chat/create-agent"}>
                          <button className="btn btn-primary btn-wave me-1">
                            <HiOutlineViewGridAdd />&nbsp;
                            <span className='d-sm-inline d-md-none  d-lg-inline'>
                              New Agent
                            </span>
                          </button>
                        </Link>
                        <button onClick={startConversation} className="btn btn-primary btn-wave">
                          <RiChatNewLine />&nbsp;
                          <span className='d-sm-inline  d-md-none d-lg-inline'>
                            New Conversation
                          </span>
                        </button>
                      </>
                    ) : (
                      <>
                        <button className="btn btn-link text-red" style={{"color": "red"}}>
                          No Credits !
                        </button>
                        <button onClick={topUpCredits} className="btn btn-primary btn-wave">
                          <FaMoneyCheckDollar />&nbsp;
                          <span className='d-sm-inline  d-md-none d-lg-inline'>
                            Buy Credits
                          </span>
                        </button>
                      </>
                    )
                  }

                </div>
              </div>

              <div className="d-flex">
                <div className="mx-auto p-0 w-100">
                  {
                    sessions.length === 0 && (
                      <NoHistory />
                    )
                  }
                  {
                    sessions.length !== 0 && (
                      <div className={`list-unstyled mb-0 w-100`} >
                        {
                          sessions.map((session, index) => (
                            <SessionItem
                              session={session}
                              key={index}
                              handleSessionDelete={handleShowDeleteDialog}
                              handleSessionForward={handleSessionForward}
                              handleSessionShare={handleSessionShare}
                              isMine={showSession === "mine"}
                            />
                          ))
                        }

                      </div>
                    )
                  }
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      {
        !isMd && (
          <div className="session-quick-todo">
            <QuickTodo />
          </div>
        )
      }

      {
        isSharing && (
          <Share />
        )
      }
      {
        isDeleting && (
          <ConfirmDialog message={null}
            onCancel={() => {
              setIsDeleting(null)
            }}
            onConfirm={() => {
              handleSessionDelete(isDeleting)()
              setIsDeleting(null)
            }}
          />
        )
      }

    </>
  )


}

export default Sessions