import React, { useEffect } from 'react'
import { PiSignOutDuotone } from "react-icons/pi";
import { IoMdMenu } from "react-icons/io";
import { useAuthUser, useSignOut } from 'react-auth-kit';
import { useAppContext } from '../context/app.context';

import LogoImg from '../assets/img/logo.png'
import { useNavigate } from 'react-router-dom';
import { useSessionStore } from '../context/session.store';
import { MdClearAll } from 'react-icons/md';

function AppHeader() {
  
  const signOut = useSignOut()
  const authedUser = useAuthUser()()
  const navigate = useNavigate()
  const { handleMenu, isLoading } = useAppContext()

  const checkWooStoreConnection = useSessionStore(state => state.checkWooStoreConnection) 
  const sessionId = useSessionStore(state => state.sessionId)
  const storeIsConnected = useSessionStore(state => state.storeIsConnected)
  const storeData = useSessionStore(state => state.store)

  
  useEffect(() => {
    if (sessionId) {
      checkWooStoreConnection({sessionId: sessionId!})
    }
  }, [checkWooStoreConnection, sessionId])


  const handleSignOut = () => {
    // remove sessionId from local storage
    localStorage.removeItem("sessionId")
    signOut()
    navigate('/login')
  }

  return (
    <div className="appHeader">
      <div className="pageTitle left" >
        {
          (authedUser?.user_login !== 'woo_gpt_user') && (
            <button className="btn btn-link" onClick={() => handleMenu(true)}>
              <IoMdMenu />
            </button>
          )
        }
        <span className='logo'>
          <img src={storeData?.store_logo || LogoImg} alt="" />
        </span>
        <span>
          {
            isLoading && (
              <div className="spinner-border text-primary mx-4 mt-1" role="status" />
            )
          }
        </span>
      </div>
      <div className="right">
        {
          authedUser?.user_login !== 'woo_gpt_user' ? (
            <button title='Log out' onClick={() => handleSignOut()} type="button" className="btn btn-light">
              <span className="headerButton">
                <PiSignOutDuotone />
              </span>
            </button>
          ) : (
            <>
              <button title='Reset' onClick={() => handleSignOut()} type="button" className="btn btn-light">
                <span className="headerButton">
                  <MdClearAll />
                </span>
              </button>
            </>
          )
        }
      </div>
    </div>

  )
}

export default AppHeader