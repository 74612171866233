import React from "react";
import { Link } from "react-router-dom";
import { WP_URL } from "../lib/config";
import { MdClose, MdOutlineManageAccounts } from "react-icons/md";
import { RiMoneyDollarBoxLine, RiMedal2Line } from "react-icons/ri";
import userAvatar from "../assets/img/sample/avatar/avatar1.jpg";
import { BsFillHexagonFill } from "react-icons/bs";

export function AuthdSidebar({
  user,
  handleMenu,
  token,
  userCredits,
}: {
  user;
  handleMenu: (state: boolean) => void;
  userCredits: {
    credits: number;
    earnings: {
      kin: number;
    };
  };
  token: string;
}) {


  return (
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-body p-0">
          {/* profile box */}
          <div className="profileBox pt-2 pb-2">
            <div className="image-wrapper">
              <img src={userAvatar} alt="profile" className="imaged  w36" />
            </div>
            <div className="in">
              <strong>{user.display_name || "No Name"}</strong>
              <div className="text-muted">
                {user.user_login || "no username"}
              </div>
            </div>
            <button
              onClick={() => handleMenu(false)}
              className="btn btn-link btn-icon sidebar-close"
              style={{padding: '0'}}
            >
              <MdClose />
            </button>
          </div>
          {/* * profile box */}
          {/* balance */}
          <div className="sidebar-balance">
            <div className="listview-title">Balance</div>
            <div className="in">
              <h1 className="amount">$ { userCredits.credits > 0 ? userCredits.credits.toFixed(2) : '0.00' }</h1>
            </div>
            <div className="listview-title">KIN Earned</div>
            <div className="in">
              <h4 className="amount"><BsFillHexagonFill className="d-inline" /> { 
                userCredits.earnings.kin > 0 ? userCredits.earnings.kin.toFixed(0) : '0' 
                }
              </h4>
            </div>
          </div>
          {/* * balance */}
          {/* action group */}
          <div className="action-group">
            <a
              target="_blank"
              href={`${WP_URL}/wp-json/pxl-store-net/v1/validate?j0t=${token}&redirectUrl=${WP_URL}/shop`}
              className="action-button"
              rel="noreferrer"
            >
              <div className="in">
                <div className="iconbox">
                  <RiMoneyDollarBoxLine />
                </div>
                Top-up
              </div>
            </a>
            <a
              target="_blank"
              href={`${WP_URL}/wp-json/pxl-store-net/v1/validate?j0t=${token}&redirectUrl=${WP_URL}/my-account`}
              className="action-button"
              rel="noreferrer"
            >
              <div className="in">
                <div className="iconbox">
                  <MdOutlineManageAccounts />
                </div>
                My Account
              </div>
            </a>
            <a
              target="_blank"
              href={`${WP_URL}/wp-json/pxl-store-net/v1/validate?j0t=${token}&redirectUrl=${WP_URL}/my-account-2/points/`}
              className="action-button"
              rel="noreferrer"
            >
              <div className="in">
                <div className="iconbox">
                  <RiMedal2Line />
                </div>
                Points
              </div>
            </a>
          </div>
          {/* * action group */}
          {/* menu */}
          <div className="listview-title mt-1">Menu</div>
          <ul className="listview flush transparent no-line image-listview">
            <li>
              <Link to="chat" className="item">
                <div className="in">Your Agency</div>
              </Link>
            </li>
            <li>
              <a
                href="https://www.instagram.com/stories/highlights/17976570953496344/"
                className="item"
                target="_blank"
                rel="noreferrer"
              >
                <div className="in">How to</div>
              </a>
            </li>
            <li>
              <a
                className="item"
                target="_blank"
                href={`${WP_URL}/wp-json/app-jwt-login/v1/autologin?hdx=${token}&redirectUrl=${WP_URL}/cart/?add-to-cart=33&quantity=1&coupon="5USDFREE"`}
                rel="noreferrer"
              >
                <div className="in">✨ Get 💲5 Free Credit 🫶</div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
