import React, { useState } from 'react'
import { SHARP_API_URL, WP_URL } from '../lib/config'
import { cachedUrl } from '../lib/utils'

function PhotoItems({userPhoto: media, handleClick, selectedPhoto, handlePost}) {
  const [onHover, setOnHover] = useState(false)
  return (
    <>
      {
        media.url.includes('.mp4') ? (
          <div onMouseLeave={() => setOnHover(false)} onMouseEnter={() => setOnHover(true)} className={`${media.url === selectedPhoto ? 'active-media' : ""} gallery_product  col-md-3 col-sm-12 col-xs-3 ${onHover ? 'show-controls' : ''} `}>
            <video className="h-auto w-100 rounded-lg" controls>
              <source src={media.url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="btn-group">
              <button onClick={() => handlePost(media)} className="btn btn-sm px-3 btn-link">Post</button>
              <a href={`${WP_URL}/wp-json/pxl-v-cam-app/v1/fetch-img?image_url=${media.url}`} className="btn btn-sm px-3 btn-link" target='_blank' rel="noreferrer" >Share</a>
            </div>
          </div>
        ) : (
          <div onMouseLeave={() => setOnHover(false)} onMouseEnter={() => setOnHover(true)}  className={`${media.url === selectedPhoto ? 'active-media' : ""} gallery_product  col-md-3 col-sm-12 col-xs-3 ${onHover ? 'show-controls' : ''}`}>
            <img className="h-auto w-100 rounded-lg" src={cachedUrl(media.url) + `?w=250&h=250`} alt="" />
            <div className="btn-group">
              <button onClick={() => handlePost(media)} className="btn btn-sm px-3 btn-link">Post</button>
              <a href={`${SHARP_API_URL}/share-image?url=${media.url}`} className="btn btn-sm px-3 btn-link" target='_blank' rel="noreferrer" >Share</a>
            </div>
          </div>
        )
      }
    </>
  )
}

export default PhotoItems