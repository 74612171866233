import { useState } from 'react'
import { FaStop } from 'react-icons/fa6'
import { MdOutlineCircle } from "react-icons/md";
import { useAgentStore } from '../../../context/agent.store';
import { useSessionStore } from '../../../context/session.store';
import { AgentTools } from '../../../lib/types';

export function ConnectWooAgentTool() {

  const [form, setForm] = useState({
    email: "",
    woo_ck_key_r: "",
    woo_cs_key_r: "",
    woo_ck_key_w: "",
    woo_cs_key_w: "",
    site_url: "",
    storeName: "",
    store_logo: ""
  })
  const [connectStoreError, setConnectStoreError] = useState<any>(null)
  const [isLoading, setLoading] = useState(false)

  const [checked, setChecked] = useState(false);
  const [isConnected, setIsConnected] = useState(false);

  const createWooStoreConnection = useSessionStore(state => state.createWooStoreConnection)
  const onEnterUrls = useAgentStore(state => state.addToolData)


  const handleSubmit = async (e) => {
    setLoading(true)
    setConnectStoreError(null)
    e.preventDefault()

    const tokenResponse = await createWooStoreConnection({ ...form, sessionId: "" }, 'write').catch(err => {
      setConnectStoreError(`Error Connecting to Woocommerce`)
      setLoading(false)
    })

    if (!tokenResponse) {
      return
    }
    setIsConnected(true)
    setLoading(false)
    onEnterUrls({
      data: {
        email: form.email,
        site_url: form.site_url,
        store_name: form.storeName,
        store_logo: form.store_logo
      },
      name: AgentTools.WOOTOOL
    })

  }

  const handleChange = () => {
    setChecked(!checked);
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-12 mx-auto">
          <div className="section mt-2 text-center">
            <h1>Connect your WooCommerce Store</h1>
            <h3>AI Store Manager</h3>
            <h4>
              To try out your <strong>AI Store Manager</strong>, you should provide write-enabled keys which will allow your assistant
              query and also make changes on your store to products, orders, customers and other data. <br />Changes can be made.
            </h4>
          </div>
          <div className="section mb-5 p-2">
            <form onSubmit={handleSubmit}>
              <div className="card">
                <div className="card-body pb-1">
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="email1">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email1"
                        placeholder="Your email"
                        value={form.email}
                        onChange={e => setForm({ ...form, email: e.target.value })}
                      />
                    </div>
                  </div>
                  <div className="form-group basic">
                    <div className="input-wrapper mt-2">
                      <label htmlFor="" className="label">
                        Enable Shopping Assistant?
                      </label>
                      <p>
                        <span style={{ fontSize: "12px", lineHeight: "1.2" }}>
                          Enable a read-only assistant to
                          query your store for products. <br />No changes can be made.
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="consumerKey1">
                        Consumer Key
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="consumerKey1"
                        autoComplete="off"
                        placeholder="Your Consumer Key"
                        value={form.woo_ck_key_r}
                        onChange={e => setForm({ ...form, woo_ck_key_r: e.target.value })}
                      />
                    </div>
                  </div>
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="consumerSecret1">
                        Consumer Secret
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="consumerSecret1"
                        autoComplete="off"
                        placeholder="Your Consumer Secret"
                        value={form.woo_cs_key_r}
                        onChange={e => setForm({ ...form, woo_cs_key_r: e.target.value })}
                      />
                    </div>
                  </div>
                  <div className="form-group basic">
                    <div className="input-wrapper mt-2">
                      <label htmlFor="" className="label">
                        <input type='checkbox' checked={checked} onChange={handleChange} />&nbsp;
                        Enable Store Manager?
                      </label>
                      <p>
                        <span style={{ fontSize: "12px", lineHeight: "1.2" }}>
                          Also enable a read and write assistant to
                          manage your store for products, orders and customer data. <br />⚠️&nbsp;Changes can be made.
                        </span>
                      </p>
                    </div>
                  </div>
                  {
                    checked && (
                      <>
                        <div className="form-group basic">
                          <div className="input-wrapper">
                            <label className="label" htmlFor="consumerKey1">
                              Consumer Key
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="consumerKey1"
                              autoComplete="off"
                              placeholder="Your Consumer Key"
                              value={form.woo_ck_key_w}
                              onChange={e => setForm({ ...form, woo_ck_key_w: e.target.value })}
                            />
                          </div>
                        </div>
                        <div className="form-group basic">
                          <div className="input-wrapper">
                            <label className="label" htmlFor="consumerSecret1">
                              Consumer Secret
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="consumerSecret1"
                              autoComplete="off"
                              placeholder="Your Consumer Secret"
                              value={form.woo_cs_key_w}
                              onChange={e => setForm({ ...form, woo_cs_key_w: e.target.value })}
                            />
                          </div>
                        </div>
                      </>
                    )
                  }

                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="siteUrl1">
                        Site URL
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="siteUrl1"
                        placeholder="Your Site Url"
                        value={form.site_url}
                        onChange={e => setForm({ ...form, site_url: e.target.value })}
                      />
                      <i className="clear-input">
                        <FaStop />
                      </i>
                    </div>
                  </div>
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="siteUrl1">
                        Store Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="StoreName"
                        placeholder="Your Store Name"
                        value={form.storeName}
                        onChange={e => setForm({ ...form, storeName: e.target.value })}
                      />
                    </div>
                  </div>
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="siteLogo1">
                        Store Logo Url
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="StoreLogo"
                        placeholder="https://imgbb.com/examplelogo"
                        value={form.store_logo}
                        onChange={e => setForm({ ...form, store_logo: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {
                connectStoreError && (
                  <div className="d-flex justify-content-center mt-2">
                    <div className="alert alert-danger mb-1" role="alert">
                      {connectStoreError || 'Login attempt failed. Please try again.'}
                    </div>
                  </div>
                )
              }
              {
                !isConnected ? (
                  <div className="form-button mt-3  transparent">
                    <button disabled={isLoading} type="submit" className="btn btn-primary ">
                      Connect&nbsp;
                      {
                        isLoading && (

                          <span className='animate__animated animate__heartBeat animate__infinite'>
                            <MdOutlineCircle />
                          </span>
                        )
                      }
                    </button>
                  </div>
                ) : (
                  <div className="form-button mt-3  transparent">
                    <button disabled={true} className="btn btn-success ">
                      Connected&nbsp;
                    </button>
                  </div>
                )
              }
            </form>
          </div>
        </div>
      </div>
    </>

  )
}