import { nodeAxiosInstance } from "./http/nodeAxiosInstance";


export const fetchAvailableLLMs = async () => {

  const response = await nodeAxiosInstance.get(`/api/agents/available-llms`)

  return response.data as {
    name: string;
    provider: string;
  }[]
}