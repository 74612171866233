import { useState } from 'react';
import { useAgentStore } from '../../context/agent.store';
import { AgentTools } from '../../lib/types';

export function SolanaQueryForm() {
    const [rpcUrl, setRpcUrl] = useState('');
    const [selectedTask, setSelectedTask] = useState('');
    const [tokenMintAddress, setTokenMintAddress] = useState('');
    const [response, setResponse] = useState<any>(null);
    const [inputValue, setInputValue] = useState('');


    const onEnterUrls = useAgentStore(state => state.addToolData)


    const tasks = [
        {
            id: 'getTokenLargestAccounts',
            title: 'Get Largest Token Accounts',
            description: 'Find out which accounts hold the largest amounts of a specific token.',
        },
        {
            id: 'getAccountInfo',
            title: 'Get Account Information',
            description: 'Retrieve detailed information about a specific account on the Solana blockchain.',
        },
        {
            id: 'getBalance',
            title: 'Check Account Balance',
            description: 'Check the current balance of an account on the Solana blockchain.',
        },
        {
            id: 'getTransaction',
            title: 'Get Transaction Details',
            description: 'Retrieve detailed information about a specific transaction using its signature.',
        },
        // Add more tasks as needed
    ];

    const handleRpcUrlChange = (e) => setRpcUrl(e.target.value);
    const handleTaskClick = (task) => setSelectedTask(task.id);
    const handleTokenMintAddressChange = (e) => setTokenMintAddress(e.target.value);
    const handleInputChange = (e) => setInputValue(e.target.value);


    const handleSubmit = async (e) => {
        e.preventDefault();
        // Implement the logic to execute the selected Solana query using the provided RPC URL
        // Update the response state with the result of the query
        onEnterUrls({
          data: {
            rpcUrl,
            method: selectedTask,
            params: selectedTask === 'getTransaction' ? [inputValue, 'json'] : [inputValue]
          },
          name: AgentTools.WEB3TOOL
        })

        setResponse(true)
    };

    return (
        <div className="container mt-4">
            <h2>Execute Solana Blockchain Query</h2>

            <div className="mb-3">
                <label htmlFor="rpcUrl" className="form-label">RPC URL</label>
                <input
                    type="text"
                    id="rpcUrl"
                    className="form-control"
                    value={rpcUrl}
                    onChange={handleRpcUrlChange}
                    required
                />
                <div className="form-text">Enter the RPC URL of the Solana node you want to query (e.g., https://api.mainnet-beta.solana.com).</div>
            </div>

            <div className="mb-3">
                <label className="form-label">Select a Task</label>
                <div className="list-group">
                    {tasks.map(task => (
                        <button
                            key={task.id}
                            type="button"
                            className={`list-group-item list-group-item-action ${selectedTask === task.id ? 'active' : ''}`}
                            onClick={() => handleTaskClick(task)}
                        >
                            <h5 className="mb-1">{task.title}</h5>
                            <p className="mb-1">{task.description}</p>
                        </button>
                    ))}
                </div>
                <div className="form-text">Click on a task to select it for execution.</div>
            </div>
            <>
                <div className="mb-3">
                    <label htmlFor="inputValue" className="form-label">
                        {selectedTask === 'getTransaction' ? 'Transaction Signature' : 'Token Mint Address'}
                    </label>
                    <input
                        type="text"
                        id="inputValue"
                        className="form-control"
                        value={inputValue}
                        onChange={handleInputChange}
                        required
                    />
                    <div className="form-text">
                        {selectedTask === 'getTransaction' ? 
                            'Enter the transaction signature associated with the selected task.' : 
                            'Enter the token mint address or account public key associated with the selected task.'
                        }
                    </div>
                </div>

                <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Save</button>
            </>

            {response && (
                <div className="mt-4">
                    <h4>Saved</h4>
                </div>
            )}
        </div>
    );
}

export default SolanaQueryForm;
