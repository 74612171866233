import { TAddAgentArgs, TToolDataType } from "../../lib/types";
import { apolloClient } from "./client";
import { CREATE_NEW_CUSTOM_AGENT, GET_USER_CUSTOM_AGENT } from "./gql/agent";
import { CREATE_NEW_SESSION_AGENT } from "./gql/sessions";


export const addCustomAgent = async (agentArgs: TAddAgentArgs, tools: TToolDataType | null = null) => {
  const response = await apolloClient.mutate({
    mutation: CREATE_NEW_CUSTOM_AGENT,
    variables: {
      data: {...agentArgs, tools},
    },
  });
  return response.data.createAgent;
};

export const getUserCustomAgents = async () => {
  const response = await apolloClient.mutate({
    mutation: GET_USER_CUSTOM_AGENT,
  });
  return response.data.agents;
};