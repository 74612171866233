
import LogoImg from '../assets/img/logo.png'

function PlatformHeader() {
  


  return (
    <div className="appHeader">
      <div className="pageTitle left" >
        <span className='logo'>
          <img src={LogoImg} alt="" />
        </span>
        <span>
        </span>
      </div>
      <div className="right">

      </div>
    </div>

  )
}

export default PlatformHeader