import { Mention, MentionsInput } from "react-mentions";


const PostEditor = ({onChange, data, value, isExpanded}) => {
  return (
    <MentionsInput
      className={` form-control mention-textarea ${isExpanded ? 'mention-expanded' : '' }`}
      value={value || ""}
      onChange={onChange}
      placeholder={"Start a new task."}
      a11ySuggestionsListLabel={"Suggested mentions"}
      allowSuggestionsAboveCursor={true}
    >
      <Mention trigger="" data={[]}  />
    </MentionsInput>
  );
};

export default PostEditor;


