import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { AGENT_TYPE, AgentTools } from '../../../lib/types'
import { FaStop } from 'react-icons/fa6'
import { MdOutlineCircle } from 'react-icons/md'
import { useAuthUser } from 'react-auth-kit'

import querystring from 'query-string'
import { checkConnectionWithWp } from '../../../requests/fetch'
import toast from 'react-hot-toast';
import { completeDescription } from '../../../requests/agents-req'
import { RiSaveLine } from 'react-icons/ri'
import { useAgentStore } from '../../../context/agent.store'
import { useAppContext } from '../../../context/app.context'


function CreateAssistant() {
  const params = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const agentType = params.agentType as AGENT_TYPE
  const user = useAuthUser()()

  const returnrdUserId = searchParams.get('user_id')
  const returnedSuccess = searchParams.get('success')
  const hasReturned = searchParams.get('returned') === "yes"

  const [isLoading, setLoading] = useState(false)

  const [isConnected, setIsConnected] = useState(false);

  const { showNotification } = useAppContext()

  const navigate = useNavigate()

  const addAgent = useAgentStore(state => state.saveCustomAgent)
  const fetchCustomAgents = useAgentStore(state => state.fetchCustomAgents)
  const onEnterUrls = useAgentStore(state => state.addToolData)


  const [form, setForm] = useState({
    goal: "",
    name: null as string | null,
    backstory: "",
    email: "",
    site_url: "",
    storeName: "",
    store_logo: "",
    agentName: ""
  })

  const openConnect = useCallback(async () => {
    let domain = location.host;
    let protocol = location.protocol;

    const return_url = `${protocol}//${domain}/chat/create-agent/woo/woocommerce_admin_agent?returned=yes`

    const store_url = form.site_url;
    const endpoint = '/wc-auth/v1/authorize';
    const params = {
      app_name: 'Tohju',
      scope: agentType === AGENT_TYPE.WOOCOMMERCE_ADMIN_AGENT ? 'read_write' : 'read',
      user_id: `${form?.email}::${form.site_url}`,
      return_url,
      callback_url: process.env.NODE_ENV == 'production' ?
        `https://api.tohju.com/api/webhooks/create-woo-agent-handler` :
        // 'https://webhook.site/6c23c241-49b2-4d9c-a4d8-386449ea6660' 
        `https://vscode.k0d3d.link/api/webhooks/create-woo-agent-handler` //@todo: will move to env
    };
    const query_string = querystring.stringify(params).replace(/%20/g, '+');
    console.log(store_url + endpoint + '?' + query_string)
    window.open(store_url + endpoint + '?' + query_string, "_blank");

  }, [user, form])

  const formName = {
    [AGENT_TYPE.WOOCOMMERCE_ADMIN_AGENT]: "Store Manager",
    [AGENT_TYPE.WOOCOMMERCE_SHOP_AGENT]: "Shop Assistant"
  }


  useEffect(() => {
    async function checkAccess() {

      if (hasReturned && `${returnedSuccess}` == '1') {
        // @ts-ignore
        const [email, siteUrl] = returnrdUserId?.split("::")
        try {
          const response = await checkConnectionWithWp({
            email, siteUrl
          })

          if (response) {
            setForm(form => ({
              ...form,
              email,
              site_url: siteUrl
            }))
            return setIsConnected(true)
          }

        } catch (e) {

          toast.error(`Could not complete request. \n\nFailed verify newly created ${formName[agentType]}.`)
        }

      }

    }

    checkAccess()

  }, [returnedSuccess, returnrdUserId, hasReturned])

  const getDescription = async (form) => {
    try {
      setLoading(true)
      const completed = await completeDescription(`${form.agentName}, ${formName[agentType]} at ${form.site_url}`).catch(() => setLoading(false))
      if (!completed) {
        return setLoading(false)
      }
      const { name, goal, backstory } = completed
      setForm(prev => ({
        ...prev,
        goal: goal.replace("Goal: ", "").trim(),
        backstory: backstory.replace("Backstory: ", "").trim(),
        name: name && name.replace("Name: ", "").trim()
      }))
      setLoading(false)

    } catch (error) {
      console.log(error)
    }
  }


  const saveAgent = async () => {
    onEnterUrls({
      data: {
        email: form.email,
        site_url: form.site_url,
        store_name: form.storeName,
        agent_name: form.agentName
      },
      name: AgentTools.WOOTOOL
    })
    //
    await addAgent({
      agentType: AGENT_TYPE.CUSTOM_AGENT,
      backstory: form.backstory,
      goal: form.goal,
      name: form.name || 'New Agent',
      taskDescription: `${form.agentName}, ${formName[agentType]} at ${form.site_url}`
    })

    showNotification({
      title: 'Agent Created',
      message: form.name + ' Added',
      type: 'bg-success'
    });

    navigate('/chat')

    await fetchCustomAgents()
  }

  return (
    <div className="row create-woo-agent">
      <div className="col-md-12">
        <div className="main-mail-container gap-4 mb-5 d-lg-flex">
          <div className="total-mails mt-5 mt-lg-5">
            <div className="p-3 d-flex align-items-center border-bottom">
              <div className="me-3">
                <h3>Add a {formName[agentType]} Agent </h3>
              </div>
              <div className="unstyled inbox-pagination ms-auto mb-0 ps-3">


              </div>
            </div>
            <div className='p-3 ms-3 mb-4'>
              <div className="row">
                <div className="col-md-8">
                  <div className="mt-2 text-left create-agent-title">
                    <h1>Connect your WooCommerce Store</h1>
                    <h3>AI Store Manager</h3>
                    <h4>
                      To try out your <strong>AI {formName[agentType]}</strong>, you should provide write-enabled keys which will allow your assistant
                      query and also make changes on your store to products, orders, customers and other data. <br />
                      {
                        agentType === AGENT_TYPE.WOOCOMMERCE_ADMIN_AGENT && (
                          <strong>
                            ⚠️ Changes can be made.
                          </strong>
                        )
                      }
                    </h4>
                  </div>
                </div>
              </div>
              <div className='row' >
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-title">
                      <h3 className='my-2'>
                        Step 1
                      </h3>
                    </div>
                    <div className="card-body">
                      <div className="form-group basic">
                        <div className="input-wrapper">
                          <label className="label" htmlFor="siteLogo1">
                            Your Store Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="StoreEmail"
                            placeholder="email@yourstore.com"
                            value={form.email}
                            onChange={e => setForm({ ...form, email: e.target.value })}
                          />
                          <p className='fs-6'>
                            The user email address for the store. This email must have Shop Manager permissions on Wordpress.
                          </p>
                        </div>
                      </div>
                      <div className="form-group basic">
                        <div className="input-wrapper">
                          <label className="label" >
                            Site URL
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="siteUrl1"
                            placeholder="Your Site Url"
                            value={form.site_url}
                            onChange={e => setForm({ ...form, site_url: e.target.value })}
                          />
                          <i className="clear-input">
                            <FaStop />
                          </i>
                        </div>
                      </div>

                    </div>
                    <div className="card-footer d-flex">
                      {
                        !isConnected ? (
                          <div className="form-button mt-3  transparent">
                            <button
                              disabled={isLoading || form.site_url === "" || form.email == ""}
                              onClick={() => openConnect()}
                              className="btn btn-primary "
                            >
                              Request Access&nbsp;
                              {
                                isLoading && (

                                  <span className='animate__animated animate__heartBeat animate__infinite'>
                                    <MdOutlineCircle />
                                  </span>
                                )
                              }
                            </button>
                          </div>
                        ) : (
                          <div className="form-button mt-3  transparent">
                            <button disabled={true} className="btn btn-success ">
                              Connected&nbsp;
                            </button>
                          </div>
                        )
                      }
                    </div>
                  </div>
                  <div className="card mt-3">
                    <div className="card-title">
                      <h3 className='mb-2 mt-4'>
                        Step 2
                      </h3>
                    </div>
                    <div className="card-body">
                      <div className="form-group basic">
                        <div className="input-wrapper">
                          <label className="label" >
                            Store Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="StoreName"
                            placeholder="Your Store Name"
                            value={form.storeName}
                            onChange={e => setForm({ ...form, storeName: e.target.value })}
                          />
                        </div>
                      </div>
                      <div className="form-group basic">
                        <div className="input-wrapper">
                          <label className="label" >
                            Agent Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="Agent_name"
                            placeholder="Lisa M"
                            value={form.agentName}
                            onChange={e => setForm({ ...form, agentName: e.target.value })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="card-footer d-flex">

                      <div className="form-button mt-3  transparent">
                        <button onClick={() => getDescription(form)} className="btn btn-success ">
                          Preview&nbsp;
                        </button>
                      </div>
                    </div>
                  </div>

                  {
                    form.goal && form.backstory && (
                      <div className="card mt-3">
                        <div className="card-title">
                          <h3 className='mb-2 mt-4'>
                            About your Agent
                          </h3>
                        </div>
                        <div className="card-body">
                          <p>
                            {form.goal}
                          </p>
                          <p>
                            {form.backstory}
                          </p>
                        </div>
                      </div>
                    )
                  }
                </div>



              </div>
              <div className="mt-0">
                <div className="d-flex flex-col pt-4">
                  {
                    form.name && form.goal && form.backstory && (
                      <button onClick={() => saveAgent()} className="btn btn-primary btn-wave">
                        <RiSaveLine />&nbsp;
                        <span className=''>
                          Create {formName[agentType]}
                        </span>
                      </button>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateAssistant