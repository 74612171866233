import { useState } from 'react'
import { RiSaveLine } from "react-icons/ri";
import { completeDescription } from '../../requests/agents-req';
import { MdOutlineCircle } from 'react-icons/md';
import { BsArrowRight, BsLightbulb } from 'react-icons/bs';
import { useAgentStore } from '../../context/agent.store';
import { AGENT_TYPE, AgentTools } from '../../lib/types';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../context/app.context';
import { FaEdit } from 'react-icons/fa';
import { RssAgentFeed } from './RssAgent';
import { SolanaQueryForm } from './CreateWeb3';
import { ConnectWooAgentTool } from './woo/ConnectAssistant';

const MAX_URLS = 4

function CreateAgent() {
  const [isEdit, setEdit] = useState(false)
  const [form, setForm] = useState({
    goal: "",
    backstory: "",
    name: null as string | null
  })

  const { showNotification } = useAppContext()

  const navigate = useNavigate()

  const addAgent = useAgentStore(state => state.saveCustomAgent)
  const fetchCustomAgents = useAgentStore(state => state.fetchCustomAgents)
  const isLoading = useAgentStore(state => state.isLoading)
  const setLoading = useAgentStore(state => state.setLoading)

  const [desc, setDesc] = useState("")

  const [selectedTool, setSelectedTool] = useState<AgentTools| "" >("")



  const saveAgent = async () => {
    //
    await addAgent({
      agentType: AGENT_TYPE.CUSTOM_AGENT,
      backstory: form.backstory,
      goal: form.goal,
      name: form.name || 'New Agent',
      taskDescription: desc
    })
    showNotification({
      title: 'Agent Created',
      message: form.name + ' Added',
      type: 'bg-success'
    });
    navigate('/chat')
    await fetchCustomAgents()
  }

  const toggleEdit = (e) => {
    e.preventDefault()
    setEdit(prev => !prev)
  }

  const getDescription = async () => {
    try {
      setLoading(true)
      const completed = await completeDescription(desc).catch(() => setLoading(false))
      if (!completed) {
        return setLoading(false)
      }
      const {name, goal, backstory} = completed
      setForm(prev => ({
        ...prev,
        goal: goal.replace("Goal: ", "").trim(),
        backstory: backstory.replace("Backstory: ", "").trim(),
        name: name && name.replace("Name: ", "").trim()
      }))
      setLoading(false)

    } catch (error) {
      console.log(error)
    }
  }



  return (
    <div className="row">
      <div className="col-md-12">
        <div className="main-mail-container gap-4 mb-5 d-lg-flex">
          <div className="total-mails mt-5 mt-lg-5">
            <div className="p-3 d-flex align-items-center border-bottom">
              <div className="me-3">
                <h3>{form.name || 'New Agent'}</h3>
              </div>
              <div className="unstyled inbox-pagination ms-auto mb-0 ps-3">


              </div>
            </div>
            <div className='p-3'>
              <div className='d-flex flex-column' >
                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" htmlFor="email1">
                      Describe Agent's Expertise.
                    </label>
                    <textarea
                      className="form-control"
                      id="email1"
                      placeholder="A professional writer..."
                      value={desc}
                      onChange={e => setDesc(e.target.value)}
                    />
                  </div>
                </div>
                {
                  form.goal && form.backstory && !isEdit && (
                    <div className="card my-2">
                      <div className="card-body">
                        <p>
                          {form.goal}
                        </p>
                        <p>
                          {form.backstory}
                        </p>
                      </div>
                    </div>
                  )
                }
                <div className="form-group d-flex justify-content-between my-3">
                  {
                    form.name && form.name !== '' && (
                      <>
                        <button disabled={!desc.length || isLoading} className="btn btn-link" onClick={() => getDescription()}>
                          <span className="btn-white">
                            <BsLightbulb color='blue' />
                          </span>
                          &nbsp;
                          Get Suggestions
                        </button>
                        <button disabled={!form.name || form.name == ''} onClick={toggleEdit} className={`btn ${isEdit ? 'btn-outline-danger' : 'btn-link'}`}>
                          {isEdit ? 'Close' : (<><FaEdit />' Edit Suggestion'</>)}
                        </button>
                      </>
                    )
                  }
                </div>
                {
                  isEdit && (
                    <>
                      <div className="form-group basic">
                        <div className="input-wrapper">
                          <label className="label" htmlFor="goal">
                            Agent Name
                          </label>
                          <textarea

                            className="form-control"
                            id="goal"
                            placeholder="Provide Short Name."
                            value={form.name!}
                            onChange={e => setForm(x => ({ ...x, name: e.target.value }))}
                          />
                        </div>
                      </div>
                      <div className="form-group basic">
                        <div className="input-wrapper">
                          <label className="label" htmlFor="goal">
                            Goal
                          </label>
                          <textarea

                            className="form-control"
                            id="goal"
                            placeholder="Provide smart answers. Be brief."
                            value={form.goal}
                            onChange={e => setForm(x => ({ ...x, goal: e.target.value }))}
                          />
                        </div>
                      </div>
                      <div className="form-group basic">
                        <div className="input-wrapper">
                          <label className="label" htmlFor="backstory">
                            Backstory
                          </label>
                          <textarea
                            className="form-control"
                            id="backstory"
                            placeholder="You are a chat bot. Your priority is to chat with enquirers and use tools when necessary.."
                            value={form.backstory}
                            onChange={e => setForm(x => ({ ...x, backstory: e.target.value }))}
                          />
                        </div>
                      </div>
                    </>
                  )
                }

                {
                  form.goal && form.backstory && (
                    <div className="row mt-3">
                      <div className="col-sm-12">
                        <h3>Add Tools</h3>
                      </div>
                      <div className="col-sm-12 col-md-3">
                        <select value={selectedTool} onChange={e => setSelectedTool(e.target.value as AgentTools)} className="form-select" aria-label="Default select example">
                          <option>Pick a tool</option>
                          <option value={AgentTools.RSSTOOL}>RSS Agent</option>
                          <option value={AgentTools.WEB3TOOL}>Solana Query</option>
                          <option value={AgentTools.WOOTOOL}>WooCommerce Store Connection</option>

                        </select>
                      </div>
                      <div className="col-sm-12 col-md-9 col-lg-6 ">
                        {
                          selectedTool === AgentTools.RSSTOOL && (
                            <RssAgentFeed  />
                          )
                        }
                        {
                          selectedTool === AgentTools.WEB3TOOL && (
                            <SolanaQueryForm  />
                          )
                        }
                        {
                          selectedTool === AgentTools.WOOTOOL && (
                            <ConnectWooAgentTool  />
                          )
                        }
                      </div>
                    </div>
                  )
                }

              </div>
              <div className="mt-0">
                <div className="d-flex flex-col pt-4">
                  {
                    !(form.goal && form.backstory) && (
                      <button disabled={!desc.length || isLoading} className="btn btn-primary" onClick={() => getDescription()}>
                        <span className="btn-white">
                          <BsArrowRight color='white' />
                        </span>
                        &nbsp;
                        Next
                      </button>
                    )
                  }

                  {
                    form.name && form.goal && form.backstory && (
                      <button onClick={saveAgent} className="btn btn-primary btn-wave">
                        <RiSaveLine />&nbsp;
                        <span className=''>
                          Create
                        </span>
                      </button>
                    )
                  }
                  <button className='btn btn-icon'>
                    {
                      isLoading && (<>
                        <span className='animate__animated animate__heartBeat animate__infinite'>
                          <MdOutlineCircle />
                        </span>
                      </>
                      )
                    }
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateAgent