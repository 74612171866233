
export const WP_URL = import.meta.env.VITE_APP_WP_URL ? 
  import.meta.env.VITE_APP_WP_URL : 
  "https://wp.easyvcam.online/"
  // "https://localhost:8898"

const localVars = window['PXL_APP_VARS'] || {}

export const APP_VARS = {
  ajaxUrl:  `${WP_URL}/wp-json/pxl-v-cam-app/v1`,
  nonce: '',
  ...localVars
}

export const SHARP_API_URL = import.meta.env.VITE_APP_SHARP_API_URL ? import.meta.env.VITE_APP_SHARP_API_URL : "http://localhost:3350"

export const NODE_API_URL = import.meta.env.VITE_APP_NODE_API_URL ? import.meta.env.VITE_APP_NODE_API_URL : "http://localhost:3351"

export const MAX_RSS_URLS = 4