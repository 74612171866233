import { create } from "zustand";
import { produce } from 'immer'
import { AgentTools, TAddAgentArgs, TAgent, TToolDataType, TToolData } from "../lib/types";
import { addCustomAgent, getUserCustomAgents } from "../requests/graphql/agent";
import { fetchAvailableLLMs } from "../requests/llms-req";

type TAgentStore = {
  agents: TAgent[]
  selectedAgents: TAgent[]
  isLoading: boolean
  toolData: TToolDataType 
  agentPrompts: {
    description: string
    filePath: string
    title: string
    fileContent: string
  }[] | null
  availableLLMs: {provider: string, name: string}[]
  getAvailableLLMs: () => Promise<void>
  setAgentPrompts: (prompts: any[] | null) => void
  addToolData: (data: TToolData) => void
  resetToolData: (name) => void
  setLoading: (value: boolean) => void
  saveCustomAgent: (agent: TAddAgentArgs) => void
  addSelectedAgent: (agent: TAgent) => Promise<void>
  resetSelectedAgents: () => void
  fetchCustomAgents: () => void
}


export const useAgentStore = create<TAgentStore>((set, get) => ({

  agents: [],
  isLoading: false,
  toolData: {} as TToolDataType,
  selectedAgents: [],
  agentPrompts: null,
  availableLLMs: [],
  getAvailableLLMs: async () => {
    get().setLoading(true)
    const llms = await fetchAvailableLLMs()
    set(produce(get(), (state) => {
      state.availableLLMs = llms.map(({name, provider}) => ({name, provider }))
      state.isLoading = false
    }))
  },
  resetSelectedAgents() {
    get().setLoading(true)
    set(produce(get(), (state) => {
      state.selectedAgents = []
      state.isLoading = false
    }))
  },
  setAgentPrompts(prompts) {
    get().setLoading(true)
    set(produce(get(), (state) => {
      state.agentPrompts = prompts
      state.isLoading = false
    }))
  },
  addSelectedAgent: async (agent) => {
    get().setLoading(true)
    set(produce(get(), (state) => {
      state.selectedAgents = [agent]
      state.isLoading = false
    }))
  },
  addToolData(data) {
    set(
      produce(get(), draft => {
        // filter out prev data 
        draft.toolData = {
          ...get().toolData, 
          [data.name]: data
        }
      })
    )
  },
  resetToolData(name) {
    set(
      produce(get(), draft => {
        draft.toolData = {
          ...get().toolData, 
          [name]: undefined
        }
      })
    )
  },
  setLoading: (value) => set(produce(get(), (state) => {
    state.isLoading = value
  })),
  saveCustomAgent: async (agent) => {
    get().setLoading(true)
    const newAgent = await addCustomAgent(agent, get().toolData)
    set(produce(get(), (state) => {
      state.agents.push(newAgent)
      state.isLoading = false
    }))
  },
  fetchCustomAgents: async () => {
    get().setLoading(true)
    const agents = await getUserCustomAgents()
    set(produce(get(), (state) => {
      state.agents = agents
      state.isLoading = false
    }))
  }

}))