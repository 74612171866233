/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useCallback, useState } from "react";
import { WooCommerceChat } from "./WooCommerceChat";
import { useSessionStore } from "../context/session.store";
import { AGENT_TYPE, TAppUser } from "../lib/types";
import { useAuthUser, useSignIn } from "react-auth-kit";
import { getWpSessionUser } from "../requests/fetch";
import { useAppContext } from "../context/app.context";
import AppNotification from "../components/Notification";
import useSocket from "../hooks/useSocket";
import { AppHelmet } from "../components/AppHelmet";
import StoreHeader from "../components/StoreHeader";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import AppHeader from "../components/AppHeader";
import { getShareSession } from "../requests/message-req";
import { useThreadStore } from "../context/thread.store";
import { useAgentStore } from "../context/agent.store";

function PubicChat() {
  const signIn = useSignIn()

  const { handleMenu } = useAppContext();
  const { hashedId: linkId } = useParams()
  const [thread, setThread] = useState({
    linkId: "",
    rootId: "",
  })

  const createNewTempSession = useSessionStore(state => state.createNewTempSession)
  const sessionId = useSessionStore(state => state.sessionId)
  const setSessionId = useSessionStore(state => state.setSessionId)
  const setLoading = useSessionStore(state => state.setLoading)
  const reload = useThreadStore(state => state.reloadSessionMessages)
  const getSessionAgents = useThreadStore(state => state.getSessionAgents)

  const user = useAuthUser()() as TAppUser;
  const webSocketClient = useSocket()
  const navigate = useNavigate()

  const rootId = thread.rootId


  const handlePublicLogin = useCallback(async () => {
    setLoading(true)

    const tokenResponse = await getWpSessionUser().catch(() => {
      setLoading(false)
    })
    
    if (!tokenResponse) return

    const token = tokenResponse?.jwt
    signIn({
      token,
      expiresIn: 7100,
      tokenType: "Bearer",
      authState: tokenResponse.user,
    })

    setLoading(false)
    navigate(0)

  }, [setLoading, signIn])

  useEffect(() => {
    if (!sessionId || sessionId === "") {
      return
    }
    if (!rootId || rootId === "") {
      return
    }
    // should use the thread id to get the messages
    reload({
      after: "",
      sessionId: sessionId!,
    })

    getSessionAgents(sessionId!)

    return () => {
      webSocketClient.logOut()
    }

  }, [sessionId, rootId])


  useEffect(() => {
    if (!user) return
    if (rootId == '') return
    // Simulate fetching sessionId from the server.
    const fetchSessionId = async () => {
      try {
        // Replace this with your actual session fetching logic.
        await createNewTempSession(
          linkId!,
          rootId
        )


      } catch (error) {
        console.error('Fetching session ID failed', error);
      }
    };

    // get session id from localstorage
    const sessionId = localStorage.getItem('sessionId');
    // if is set then use it
    if (sessionId) {
      setSessionId(sessionId);
      return;
    }
    fetchSessionId();
  }, [rootId, linkId, user]);

  useEffect(() => {
    if (linkId) {
      // remove this. it should fetch the 
      getShareSession(linkId)
      .then(({linkId, rootId}) => {
        setThread({ linkId, rootId})
      })
    }
  }, [linkId])

  useEffect(() => {
    if (user) return
    handlePublicLogin()
    return () => {
      webSocketClient.logOut();
    };
  }, [])

  if (!sessionId) {
    // Render a loading state or null while there is no session ID
    return <div>Loading session...</div>;
  }


  return (
    <>
      <AppHelmet siteTitle={`Your Agency: Tohju.com, AI Freelancing with Micropayments `} />
      <div>
        <AppNotification />
        <AppHeader />
        <div className="main-container container mx-auto md:px-6">
          <Outlet /> 
        </div>
      </div>

    </>
  )
}

export default PubicChat