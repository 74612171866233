import { create } from "zustand";
import { produce } from "immer";
import {
  AGENT_TYPE,
  TAddAgentArgs,
  TAgent,
  TGetConversationArgs,
  TMessage,
  TMessageEnum,
  TMessageSelectedLLM,
  TMessageType,
} from "../lib/types";
import {
  addSessionAgent,
  createBulkSessionMessage,
  createSessionMessage,
  createSessionTask,
  fetchSessionAgents,
  getSessionMessages,
  sendMessageReport,
} from "../requests/graphql/session";
import { generate } from "random-words";
import { gotoEnd } from "../lib/utils";

type TStore = {
  isLoading: boolean;
  messages: TMessage[];
  agents: TAgent[];
  sessionAgents: TAgent[];
  isTyping: boolean;
  isComposing: string | null;
  isUploading: boolean;
  isShowingAgents: boolean;
  setShowingAgents: (show: boolean) => void
  setIsUploading: (value: boolean) => void;
  setComposing: (value: string| null) => void;
  setTyping: (value: boolean) => void;
  setLoading: (state: any) => void;
  addMessage: (message: TMessage) => Promise<void>;
  getSessionAgents: (sessionId: string) => Promise<any>;
  getSessionMessages: (args: TGetConversationArgs) => Promise<any>;
  reloadSessionMessages: (args: TGetConversationArgs) => Promise<any>;
  resetSessionMessages: () => void;
  sendMessageUserToAgent: (
    message: Partial<TMessage>,
    sessionId: string,
    agent: TAgent,
    messageType?: TMessageType,
    selectedLLM?: TMessageSelectedLLM | null
  ) => Promise<any>;
  sendMessageUserToSession: (
    message: Partial<TMessage>,
    sessionId: string,
    selectedLLM?:TMessageSelectedLLM | null
  ) => Promise<any>;
  addAgent: (agent: TAddAgentArgs) => void;
  reportMessage: (content: string, messageId: string) => Promise<void>;
  runTask: (message: TMessage, sessionId: string, agent: TAgent, messageType: TMessageType) => Promise<void>
};

export const useThreadStore = create<TStore>((set, get) => {
  return {
    isLoading: false,
    isComposing: null,
    messages: [],
    sessionAgents: [],
    agents: [],
    isTyping: false,
    isUploading: false,
    isShowingAgents: false,
    setShowingAgents(show) {
      set(
        produce(get(), (state) => {
          state.isShowingAgents = show
        })
      );      
    },
    resetSessionMessages() {
      set(
        produce(get(), (state) => {
          state.messages = [];
        })
      );
    },
    setIsUploading(value) {
      set(
        produce(get(), (state) => {
          state.isUploading = value;
        })
      );
    },
    setComposing(value) {
      set(
        produce(get(), (state) => {
          state.isComposing = value;
        })
      );
    }, 
    addAgent: async (newAgent) => {
      let words = generate(3) as string[];
      let name = `${words.join("_")}_${newAgent.agentType}`;
      await addSessionAgent({ ...newAgent, name });
      newAgent.sessionId && get().getSessionAgents(newAgent.sessionId);
    },
    setTyping(value) {
      set(
        produce(get(), (state) => {
          state.isTyping = value;
        })
      );
    },
    setLoading: (bool) => {
      set(
        produce(get(), (state) => {
          state.isLoading = bool;
        })
      );
    },
    getSessionAgents: async (sessionId: string) => {
      // call get session agents api
      const response = await fetchSessionAgents(sessionId);
      // save agents to store
      set(
        produce(get(), (state) => {
          state.sessionAgents = response.filter((agent) => agent.agentType == AGENT_TYPE.SESSION_AGENT || agent.agentType == AGENT_TYPE.CUSTOM_AGENT);
        })
      );
      return response;
    },
    /**
     * Gets the newest messages and appends it to the current messages
     * @param args Get
     */
    getSessionMessages: async (args: TGetConversationArgs) => {
      // get().resetSessionMessages();
      // call get session messages api
      get().setLoading(true);
      if (!args.after) {
        // get last message createdAt
        const lastMessage = get().messages[get().messages.length - 1];
        args.after = lastMessage?.createdAt;
      }
      const response = await getSessionMessages(args);
      // save messages to store
      set(
        produce(get(), (state) => {
          state.messages = [...state.messages, ...response].sort((a, b) => {
            return (
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
            );
          });
        })
      );
      get().setLoading(false);
    },
    /**
     * Gets all the messages for a session and replaces the current messages
     * @param args Ger
     */
    reloadSessionMessages: async (args: TGetConversationArgs) => {
      get().resetSessionMessages();
      // call get session messages api
      get().setLoading(true);
      const response = await getSessionMessages(args);
      // save messages to store
      set(
        produce(get(), (state) => {
          state.messages = response;
        })
      );
      get().setLoading(false);
      gotoEnd()
    },
    /**
     * Adds a message directly to the state. Message is likely
     * to come from a websocket event.
     * @param messageWithId
     */
    addMessage: async (messageWithId: TMessage) => {
      get().setLoading(true);
      // get last message and compare ids for  duplicate
      const lastMessage = get().messages[get().messages.length - 1];
      if (lastMessage && lastMessage.id === messageWithId.id) {
        get().setLoading(false);
        return;
      }
      set(
        produce(get(), (state) => {
          state.messages = [...state.messages, messageWithId].sort((a, b) => {
            return (
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
            );
          });
        })
      );
      gotoEnd()
      get().setLoading(false);
    },
    sendMessageUserToAgent: async (
      message,
      sessionId: string,
      agent: TAgent,
      messageType = TMessageEnum.USER_MESSAGE,
      selectedLLM
    ) => {
      get().setLoading(true);
      // call send message api
      const response = await createSessionMessage({
        messageId: message.id!,
        sessionId: sessionId,
        destination: agent.name,
        content: message.content!,
        agentType: agent.agentType,
        messageType,
        selectedLLM: selectedLLM!
      });
      // save messages to store
      set(
        produce(get(), (state) => {
          state.messages = get().messages.concat(response);
        })
      );
      get().setLoading(false);
    },
    runTask: async (
      message: TMessage,
      sessionId: string,
      agent: TAgent,
      messageType: TMessageType
    ) => {
      get().setLoading(true);
      // call send message api
      await createSessionTask({
        messageId: message.id,
        sessionId: sessionId,
        destination: agent.name,
        content: message.content,
        agentType: agent.agentType,
        props: {
          task: message.props[messageType],
        },
        messageType,
      });
      
      get().setLoading(false);
    },
    sendMessageUserToSession: async (message, sessionId) => {
      get().setLoading(true);
      // call send message api
      const response = await createBulkSessionMessage({
        sessionId: sessionId,
        content: message.content!,
        messageId: message.id!,
      });
      // save messages to store
      set(
        produce(get(), (state) => {
          state.messages = get().messages.concat(response);
        })
      );
      get().setLoading(false);
    },
    reportMessage: async (content, messageId) => {
      get().setLoading(true);
      await sendMessageReport(content, messageId);
      get().setLoading(false);
    },
  };
});
