import { nodeAxiosInstance } from "./http/nodeAxiosInstance"


export const completeDescription = async (description: string) => {

  const response = await nodeAxiosInstance.post(`/api/agents/completeDescription`, {description})
  
  return response.data as any
}

export const suggestPrompts = async (props) => {
  const response = await nodeAxiosInstance.post(`/api/agents/suggestPrompts`, props)
  
  return response.data as string[]
}

export const reloadAgentToolData = async (props) => {
  const response = await nodeAxiosInstance.post(`/api/agents/reloadAgentData`, props)
  
  return response.data as string[]
}

