import axios from 'axios';
import { APP_VARS } from '../../lib/config';
import { authHeader } from '../../lib/utils';

export const wpAxiosInstance = axios.create({
  baseURL: APP_VARS.ajaxUrl,
});
wpAxiosInstance.interceptors.request.use(
  async (config) => {
    const authToken = authHeader();
    if (authToken && authToken !== '') {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  });
wpAxiosInstance.interceptors.response.use(
  response => {
    // Check if the 'statusCode' property exists in the response data
    if (response.data && response.data.statusCode === 403) {
      // If 'statusCode' is 403, set the request status to 403
      return Promise.reject({
        response: {
          status: 403,
          data: response.data,
        },
      });
    }
    // If 'statusCode' is not 403, return the response as is
    return response;
  },
  error => {
    // Handle other errors here
    return Promise.reject(error);
  }
);
