import { gql } from '@apollo/client';


export const CREATE_NEW_SESSION = gql`
  mutation CreateAgentSession($sessionName: String!, $agentType: String) {
    createAgentSession(sessionName: $sessionName, agentType: $agentType) {
      id
      sessionName
      
    }
  }
`

export const CREATE_NEW_TEMP_SESSION = gql`
  mutation CreateTempSession($linkId: String!, $rootSessionId: String!) {
    createTempSession( rootSessionId: $rootSessionId, linkId: $linkId) {
      id
      sessionName
      
    }
  }
`

export const GET_USER_SESSIONS = gql`
  query AgentSessions {
    userSessions {
      id
      sessionName
      props
      userId
      createdAt      
    }
  }
`

export const GET_SHARED_SESSIONS = gql`
  query SharedSessions {
    sharedSessions {
      id
      sessionName
      props
      userId
      rootSession {
        id
        props
      } 
      createdAt      
    }
  }
`
export const DELETE_USER_SESSION = gql`
  mutation DeleteSessions($sessionId: String!) {
    deleteAgentSession(sessionId: $sessionId) 
  }
`

export const GET_SESSION_MESSAGES = gql`
  query Messages($data: TGetConversationArgs!) {
    messages(data: $data) {
      id
      content
      createdAt
      destination
      sender
      agentSessionId
      props
      documents {
        documentUrl
        id
      }
    }
  }
`

export const GET_SESSION_AGENTS = gql`
  query Agents($sessionId: String!) {
    agents(sessionId: $sessionId) {
      id
      name
      agentType
      props
    }
  }
`
export const CREATE_NEW_SESSION_MESSAGE = gql`  
  mutation CreateMessage($data: TCreateUserMessageArgs!) {
    createMessage(data: $data) {
      id
      content
      createdAt
      destination
      sender
    }
  }
`
export const CREATE_NEW_SESSION_TASK = gql`  
  mutation CreateTask($data: TCreateUserMessageArgs!) {
    createTask(data: $data) 
  }
`

export const CREATE_BULK_SESSION_MESSAGE = gql`  
  mutation CreateBulkMessage($data: TCreateUserMessageArgs!) {
    createBulkMessage(data: $data) {
      id
      content
      createdAt
      destination
      sender
    }
  }
`

export const CREATE_NEW_SESSION_AGENT = gql`
  mutation CreateAgent($data: TAddAgentArgs!) {
    createAgent(data: $data) {
      id
      name
      agentType
    }
  }

`;
// Define the mutation using gql tagged template literal
export const CREATE_MESSAGE_REPORT_MUTATION = gql`
  mutation CreateMessageReport($content: String!, $messageId: String!) {
    createMessageReport(content: $content, messageId: $messageId) {
      id
      content
      messageId
      createdAt 
    }
  }
`;
