import { gql } from '@apollo/client';



export const CREATE_NEW_CUSTOM_AGENT = gql`
  mutation CreateAgent($data: TAddAgentArgs!) {
    createAgent(data: $data) {
      id
      name
      agentType
    }
  }

`;

export const GET_USER_CUSTOM_AGENT = gql`
  query Agents{
    agents {
      id
      name
      agentType
      props
      
    }
  }

`;
