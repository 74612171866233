import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useAppContext } from '../context/app.context'
import { uploadUserPhoto } from '../requests/fetch'
import { getPredictionData } from '../requests/lagacy-api'
import moment from 'moment'
import { MdOutlineCircle } from 'react-icons/md'

function CompletedImageXLList() {
  const [isLoading, setLoading] = useState(false)

  const { completedImages, prediction, showNotification, onNewPhoto, getPhotos, currentPrompt } = useAppContext()
  const [outputs, setOutputs] = useState<any[]>([])
  const imgRef = useRef(null);

  const getOutputs = useCallback(async () => {
    if (!completedImages || !completedImages.length) {
      return []
    }
    const imageXLPromises = completedImages.map(async (img) => {
      return await getPredictionData(img.id)
    })
    return await Promise.all(imageXLPromises)
  }, [completedImages])

  useEffect(() => {
    getOutputs().then(out => {
      setOutputs(out.filter(o => o && o.output))
    })
  }, [getOutputs])


  // handle file upload
  const handleSaveImageUpload = async (imgSrc) => {
    setLoading(true)
    const data = await uploadUserPhoto({}, 'image/png', imgSrc, currentPrompt).catch(err => console.log(err));
    if (!data) return
    if (data.url) {
      showNotification({
        title: 'Operation Success',
        message: 'Photo Successfully Saved to your Gallery',
        type: 'bg-success'
      });
    }
    setLoading(false)
    await getPhotos();
    onNewPhoto({
      url: data.url,
      name: 'output.png',
    });

  }

  return (
    <>
      {
        (prediction && prediction.output) || (outputs && outputs.length > 0) ? (
          <div className='my-3'>
            {prediction && (
              <>
                {prediction.output && prediction.output[0].includes('.png') ? (
                  <>
                    <div className="image-wrapper mt-5 w-80">
                      <img
                        ref={imgRef}
                        style={{ "width": "100%", "height": "auto" }}
                        src={prediction.output}
                        alt='completed'
                      />
                    </div>
                    <div className="btn-group">
                      <button onClick={() => handleSaveImageUpload(prediction.output[0])} className='btn btn-link'>
                        Save Image&nbsp;
                        {
                          isLoading && (
                            <span className='animate__animated animate__heartBeat animate__infinite'>
                              <MdOutlineCircle />
                            </span>
                          )
                        }
                      </button>
                    </div>

                  </>
                )
                  : (

                    prediction.status !== 'succeeded' && prediction.status !== 'failed' && (
                      <div className="row">
                        <div className="col-md-12">
                          <div className="movie--isloading">
                            <div className="loading-image"></div>
                            <div className="loading-content">
                              <div className="loading-text-container">
                                <div className="loading-main-text"></div>
                                <div className="loading-sub-text"></div>
                              </div>
                              <div className="loading-btn"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )

                  )
                }
              </>
            )}

            <div className="list-group list-group-flush">
              {
                outputs && outputs.length > 0 && outputs.map((img, i) => {

                  return (
                    <div key={i}>
                      {
                        img.output.includes('.png') ? (
                        <a key={i} href={img.output} className="list-group-item list-group-item-action active" aria-current="true" rel="noreferrer" target='_blank'>
                          Completed on {moment(img.completed_at).calendar()} - Download
                        </a>
                        )
                        : <></>
                      }
                    </div>
                  )
                }
                )
              }
            </div>
          </div>
        ) : (<></>)
      }
    </>
  )
}

export default CompletedImageXLList