import React from 'react'

function ReportMessage({ message = '', reportMessage, close }) {
  const [report, setReport] = React.useState({
    response: message.substring(0, 150) + '...',
    reason: '',
    isSubmitting: false
  })
  return (
    <div className="modal" tabIndex={-1} style={{display: "block"}}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Report Response</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={close}
            />
          </div>
          <div className="modal-body">
            <form>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Message
                </label>
                <textarea
                  className="form-control"
                  value={report.response}
                  readOnly
                  style={{fontSize: "12px"}}
                  id="response"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputPassword1" className="form-label">
                  Why are you reporting this response?
                </label>
                <textarea
                  className="form-control"
                  value={report.reason}
                  onChange={(e) => setReport({ ...report, reason: e.target.value })}
                  id="reason"
                />
              </div>
              <button disabled={report.isSubmitting} type="submit" className="btn btn-primary" onClick={(e) => {
                e.preventDefault()
                setReport(rep => ({...rep, isSubmitting: true}))
                reportMessage(report.reason)
                }}>
                Submit
              </button>
            </form>

          </div>

        </div>
      </div>
    </div>

  )
}

export default ReportMessage