import React from 'react'
import { useAppContext } from '../context/app.context'

function AppNotification() {

  const { notification } = useAppContext()

  const notificationType = notification && notification.type  ? notification.type : 'bg-success'

  return (
    <>
      {
        notification && (
          <div id="notification-15" className={"notification-box show"}>
            <div className={`notification-dialog ios-style ${notificationType} ` }>
              <div className="notification-header">
                <div className="in">
                  <strong> { notification.title || 'Operation Success'} </strong>
                </div>
                <div className="right">
                  <span></span>
                </div>
              </div>
              <div className="notification-content">
                <div className="in">
                  <h3 className="subtitle">{notification.message}</h3>
                  <div className="text">{notification.message}</div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  )
}

export default AppNotification