import { useCallback, useEffect, useState } from 'react';
import { FaArrowRight, FaCircle, FaRobot, FaUpload } from 'react-icons/fa6';
import { FaRegUserCircle } from 'react-icons/fa';
import { CiCircleChevDown } from "react-icons/ci";
import { AGENT_TYPE, TMessage } from "../lib/types";
import moment from 'moment';
import { useAppContext } from '../context/app.context';
import { uploadUserDoc } from '../requests/message-req';
import { checkMessageSender, extractIdsAndTexts } from '../lib/utils';
import useSocket from '../hooks/useSocket';
import { useThreadStore } from '../context/thread.store';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { MdOutlineThumbDownOffAlt, MdRefresh } from "react-icons/md";
import ReportMessage from '../components/ReportMessage';

export function WooCommerceChat({ sessionId, user }) {

  const [message, setMessage] = useState("");
  const { showNotification } = useAppContext();
  const [reportModal, setReportModal] = useState({
    show: false,
    msgId: null as string | null
  });

  const webSocketClient = useSocket();

  const messages = useThreadStore(state => state.messages);
  const isLoading = useThreadStore(state => state.isLoading);
  const isTyping = useThreadStore(state => state.isTyping);


  const sendUserToAgentMessage = useThreadStore(state => state.sendMessageUserToAgent);
  const sendMessageToSession = useThreadStore(state => state.sendMessageUserToSession);
  const setLoading = useThreadStore(state => state.setLoading);
  const agents = useThreadStore(state => state.sessionAgents);
  const reloadMessages = useThreadStore(state => state.reloadSessionMessages);
  const getMessages = useThreadStore(state => state.getSessionMessages);
  const getAgents = useThreadStore(state => state.getSessionAgents);
  const reportMessage = useThreadStore(state => state.reportMessage);


  const lastMessage = messages[messages.length - 1  as number]



  const isUserMessage = useCallback((msg) => {
    return checkMessageSender(msg, user);
  }, [user]);

  useEffect(() => {
    if (!sessionId) return;

    // webSocketClient.users
    if (!user?.ID || !sessionId) return;
    webSocketClient.logIn({ sessionId, userId: user.ID });

  }, [user, sessionId, webSocketClient]);

  useEffect(() => {
    if (!sessionId || sessionId === "") {
      return;
    }

    async function getAgentsAndMessages() {
      // should use the thread id to get the messages
      const agentsResponse = await getAgents(sessionId!);
      await reloadMessages({
        after: "",
        sessionId: sessionId!,
        agentName: agentsResponse.find(agent => agent.agentType === AGENT_TYPE.WOOCOMMERCE_SHOP_AGENT)?.name
      });
    }

    getAgentsAndMessages();

  }, [agents, getAgents, reloadMessages, sessionId]);


  const handleSendMessage = async () => {
    // select agent from message
    setLoading(true);
    const hasAgents = extractIdsAndTexts(message).length > 0;
    if (hasAgents) {
      await sendMessageToSession({content: message}, sessionId!);
    } else {
      const agent = agents.find(agent => agent.agentType === AGENT_TYPE.WOOCOMMERCE_SHOP_AGENT);
      await sendUserToAgentMessage({content: message}, sessionId!, agent!);
    }

    setMessage("");
    setLoading(false);
  };


  const handleFileUpload = useCallback(async (e) => {
    if (!sessionId) return;
    // const file = e.target.files[0] as File;
    setLoading(true);
    const data = await uploadUserDoc(e.target.files, sessionId)
      .catch(err => {
        showNotification({
          title: 'Operation Failed',
          message: 'Document upload failed',
          type: 'bg-danger'
        });
      });

    webSocketClient.onNewFileUpload({
      documentUrl: data,
      sessionId,
      userEmail: user?.user_email,
      userId: user?.ID + ""
    });

    setLoading(false);
    const count = data && data.length > 0 ? data.length : 1;
    showNotification({
      title: 'Upload Success',
      message: `${count} documents Successfully Added`,
      type: 'bg-success'
    });

    await reloadMessages({
      after: "",
      sessionId
    });

    e.target.value = null;

  }, [reloadMessages, sessionId, setLoading, showNotification, user?.ID, user?.user_email, webSocketClient]);

  const handleSelectFile = e => {
    e.preventDefault();
    document.getElementById('file_input')?.click();
  };


  const fromNow = (time) => {
    return moment(time).fromNow();
  };

  const checkForNewMessages = async (e) => {
    e.preventDefault();
    await getMessages({
      after: lastMessage?.createdAt,
      sessionId
    });
  }

  return (
    <div className="app-section">
      <section className="row my-5">
        <>
          <div id="appCapsule">

            {user && messages && messages.map((msg, i) => (
              <div className={`message-item  ${msg.sender === user.user_email ? 'user' : ''} `} key={i}>
                {!isUserMessage(msg) ? (
                  <span>
                    <FaRobot />
                  </span>
                ) : (
                  <></>
                )}

                <div className={`content ${isUserMessage(msg) ? 'd-flex flex-column' : ''}`}>
                  <div className="title">
                    <p className={`${isUserMessage(msg) ? 'text-right' : ''} m-0`}>
                      {msg.sender !== user.user_email ? 'System' : 'You'}
                    </p>
                  </div>
                  <div className="bubble">
                    <div className={`${isUserMessage(msg) ? 'text-white' : 'text-black'}`}>
                      <Markdown remarkPlugins={[remarkGfm]}>{msg.content}</Markdown>
                    </div>
                  </div>
                  <div className="footer d-flex gap-2 justify-content-end">
                    {
                      !isUserMessage(msg) && (
                        <span className="flag-action" onClick={() => setReportModal({ show: true, msgId: msg.id })}>
                          <MdOutlineThumbDownOffAlt />&nbsp;
                          Report
                        </span>
                      )
                    }
                    <span className="time-stamp">
                      {fromNow(msg.createdAt)}
                    </span>
                  </div>
                </div>
                {isUserMessage(msg) ? (
                  <span>
                    <FaRegUserCircle />
                  </span>
                ) : (
                  <></>
                )}

                {
                  reportModal.show && reportModal.msgId === msg.id && (
                    <ReportMessage
                      close={() => {
                        setReportModal({ show: false, msgId: null });
                      }} 
                      reportMessage={async (reason) => {
                        await reportMessage(reason, msg.id!);
                        setReportModal({ show: false, msgId: null });
                      }} 
                      message={msg.content} 
                    />
                  )
                }
              </div>
            ))}
            <div className="message-item message-end"></div>
          </div>
          <div id="chat-bottom" className='d-flex justify-content-between align-items-end'>
            <div>
              {
                isTyping ? (
                  <button className="btn btn-primary" type="button" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    &nbsp;Thinking...
                  </button>
                ) : (
                  <button className="btn btn-primary" type="button" onClick={checkForNewMessages}>
                    {
                      isLoading ? (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      ) : (
                        <MdRefresh />
                      )

                    }
                  </button>
                )
              }
            </div>
            <button className="btn btn-link" onClick={() => document.querySelector('.message-end')?.scrollIntoView()}>
              <CiCircleChevDown scale={2} size={40} />
            </button>
          </div>
          {/* * App Capsule */}
          {/* chat footer */}
          {isLoading && (
            <div className="app-progress-bar">
              <div className="progress-bar">
                <div className="progress-bar-value"></div>
              </div>
            </div>
          )}
          <div className="chatFooter">
            <form>
              <input onChange={handleFileUpload} className="block form-control" id="file_input" type="file" style={{ "display": "none" }} />
              <button onClick={handleSelectFile} className="btn btn-icon btn-text-secondary rounded">
                <FaUpload />
              </button>
              <div className="form-group basic">
                <div className="input-wrapper">
                  <textarea
                    onChange={e => setMessage(e.target.value)}
                    className="form-control"
                    placeholder="Type a message..."
                    value={message} />
                  <i className="clear-input">
                    <FaCircle />
                  </i>
                </div>
              </div>
              <button disabled={message === ''} type="button" className="btn btn-icon btn-primary rounded" onClick={() => handleSendMessage()}>
                <FaArrowRight />
              </button>
            </form>
          </div>
        </>

      </section>
      {
        reportModal.show && (
          <div className={`modal-backdrop fade ${reportModal.show ? 'show' : ''}`}></div>
        )
      }

    </div>
  );


}
