import { useState } from 'react'
import { MdArrowDownward } from 'react-icons/md'
import { useSessionStore } from '../../context/session.store'
import { Link } from 'react-router-dom'
import { AGENT_TYPE } from '../../lib/types'

function QuickTodo() {

  const isCollapsed = useSessionStore(state => state.isCollapsed)
  const setCollapse = useSessionStore(state => state.setQuickAction)


  return (
    <>
      {
        isCollapsed ? (
          <div className="quick-todo-collapsed">
            <button onClick={() => setCollapse(false)} className="btn btn-icon p-0">
              🔔
            </button>
          </div>
        ) : (

          <div className="quick-todo flex-grow-1 d-flex align-items-center justify-content-start">
            <div className="list-container w-100">
              <div className="list-header d-flex justify-content-between">
                <h2 className='fw-light'>
                  🔔 Quick Actions
                </h2>
                <button onClick={() => setCollapse(true)} className="btn btn-icon p-0">
                  <MdArrowDownward />
                </button>
              </div>
              <ul className="list-unstyled mb-0 w-100 d-flex flex-column gap-2 ">
                <li>
                  <Link to={`/chat/create-agent/woo/${AGENT_TYPE.WOOCOMMERCE_ADMIN_AGENT}`} className="btn btn-link">
                    <span className="badge bg-light me-2">1</span>
                    <span className="fw-bold">
                      Create Store Manager
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to={`/chat/create-agent/woo/${AGENT_TYPE.WOOCOMMERCE_SHOP_AGENT}`}  className="btn btn-link">
                    <span className="badge bg-light me-2">2</span>
                    <span className="fw-bold">
                      Create Shop Assistant
                    </span>
                  </Link>
                </li>
                <li>
                  <a href="#" className="btn btn-link"></a></li>
              </ul>
            </div>
          </div>
        )
      }

    </>
  )
}

export default QuickTodo