import { CiCircleChevDown } from 'react-icons/ci';
import { MdRefresh } from 'react-icons/md';


export function ChatBottom({ isUploading, isLoading, isTyping, checkForNewMessages }) {
  return <div id="chat-bottom" className='d-flex justify-content-between align-items-end'>
    <div>
      {isTyping ? (
        <button className="btn btn-primary me-1" type="button" disabled>
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          &nbsp;Thinking...
        </button>
      ) : (
        <button className="btn btn-primary me-1" type="button" onClick={checkForNewMessages}>
          {isLoading ? (
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          ) : (
            <MdRefresh />
          )}
        </button>
      )}
      {
        isUploading && (
          <button className="btn btn-secondary ms-1" type="button" disabled>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            &nbsp;Processing upload...
          </button>
        )
      }
    </div>
    <button className="btn btn-link" onClick={() => document.querySelector('.message-end')?.scrollIntoView()}>
      <CiCircleChevDown scale={2} size={40} />
    </button>
  </div>;
}
