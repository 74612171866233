import React from 'react'
import abacusImg from '../../assets/img/abacus.png'


function NoHistory() {
  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center p-5">
        <img src={abacusImg} alt="" className='placeholder-img' />
        <h3 className='mt-3'>
          Start a new conversation
        </h3>
        <p className='text-center'>
          <strong>Write a Python script </strong>
          to automate sending daily email reports<br />

          <strong>Plan a trip </strong>
          to see the best of New York in 3 days<br />

          <strong>Write an email </strong>
          expressing your love for your parents<br />

          <strong>Plan an itinerary </strong>
          for a literary tour of England, visiting famous authors' home<br />
        </p>
      </div>
    </>)
}

export default NoHistory