/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect } from "react";
import { WooCommerceChat } from "./WooCommerceChat";
import { useSessionStore } from "../context/session.store";
import { AGENT_TYPE, TAppUser } from "../lib/types";
import { useAuthUser, useSignIn } from "react-auth-kit";
import { useAppContext } from "../context/app.context";
import AppHeader from "../components/AppHeader";
import AppNotification from "../components/Notification";
import useSocket from "../hooks/useSocket";

function DocChat() {
  const signIn = useSignIn()

  const { isOpenModal, handleMenu } = useAppContext();

  const createNewSession = useSessionStore(state => state.createNewSession)
  const sessionId = useSessionStore(state => state.sessionId)
  const setSessionId = useSessionStore(state => state.setSessionId)
  const isLoading = useSessionStore(state => state.isLoading)
  const setLoading = useSessionStore(state => state.setLoading)

  const user = useAuthUser()() as TAppUser;
  const webSocketClient = useSocket()

  useEffect(() => {
    if (!user) return
    // Simulate fetching sessionId from the server.
    const fetchSessionId = async () => {
      try {
        // Replace this with your actual session fetching logic.
        await createNewSession(
          user.roles.includes('shop_manager') ? AGENT_TYPE.WOOCOMMERCE_ADMIN_AGENT : AGENT_TYPE.WOOCOMMERCE_SHOP_AGENT
        )
      } catch (error) {
        console.error('Fetching session ID failed', error);
      }
    };

    // get session id from localstorage
    const sessionId = localStorage.getItem('sessionId');
    // if is set then use it
    if (sessionId) {
      setSessionId(sessionId);
      return;
    }
    fetchSessionId();
  }, [createNewSession, setSessionId, user]);



  if (!sessionId) {
    // Render a loading state or null while there is no session ID
    return <div>Loading session...</div>;
  }

  const handleCloseMenu = (e) => {
    console.log(e.target)
    console.log('close menu')
    handleMenu(false)
  }

  return (
    <>
      <div>
        <AppNotification />
        <AppHeader />
        <div className="main-container container mx-auto md:px-6">
          <WooCommerceChat user={user} sessionId={sessionId} />
        </div>
      </div>

    </>
  )
}

export default DocChat