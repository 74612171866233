import "../sentry"
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom"
import CreateAgent from "../components/agent/CreateAgent";
import Inbox from "../components/session/Inbox";
import Sessions from "../components/session/Sessions";
import SharedBox from "../components/session/SharedBox";
import DocChat from "../pages/DocChat";
import ErrorPage from "../pages/ErrorPage";
import PromptBoard from "../pages/PromptBoard";
import { ApolloProvider } from "@apollo/client";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../components/ErrorFallback";
import AppProvider from "../context/app.context";
import HomePage from "../pages/HomePage";
import { apolloClient } from "../requests/graphql/client";
import CreateAssistant from "../components/agent/woo/CreateAssistant";
import {Toaster} from 'react-hot-toast'


function PrivApp() {
  return (
    <ApolloProvider client={apolloClient}>
      <AppProvider>
        <ErrorBoundary FallbackComponent={ErrorFallback}  >
          <Toaster position="bottom-left" />
          <HomePage />
        </ErrorBoundary>
      </AppProvider>
    </ApolloProvider>
  )
}


const privRouter = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorPage />,
    element: (
      <PrivApp />
    ),
    children: [
      {
        index: true,
        element: <Sessions />,
      },

      {
        path: "storenet",
        element: <DocChat />,
      },
      {
        path: "prompt-board",
        element: <PromptBoard />,
      },
      {
        path: "chat",
        element: (
          <>
            <Outlet />
          </>
        ),
        children: [
          {
            path: "",
            element: <Sessions />,
          },
          {
            path: "thread/:sessionId",
            element: <Inbox />,
          },
          {
            path: "shared/:rootSessionId",
            element: <SharedBox />,
          },
          {
            path: "create-agent",
            element: <CreateAgent />,
          },
          {
            path: "shared/thread/:hashedId",
            element: <SharedBox />,
          },
          {
            path: "create-agent/woo/:agentType",
            element: <CreateAssistant />,
          },

        ]
      },
      {
        path: "/*",
        element: <Sessions />,
      },
    ],
  },
]);

const PrivateRouter = () => <RouterProvider router={privRouter} />

export default PrivateRouter