import {  useState } from 'react'
import { useAppContext } from '../context/app.context';
import Text2Img from './Text2Img';
import PromptImage from './PromptImage';


export default function CreateImage() {
  const [isLoading, setLoading] = useState(false)
  const { handleCreateImage } = useAppContext()

  const handleClick = async (form) => {
    setLoading(true)
    await handleCreateImage(form)
    setLoading(false)
  }


  return (
    <>

      <div className="row">
        <div className="col-sm-12">
          <PromptImage handleClick={handleClick} isLoading={isLoading} />
          <Text2Img />
        </div>
      </div>

    </>

  )
}