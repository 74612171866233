import CompletedImageXLList from '../components/CompletedImageXlList'
import CreateImage from '../components/CreateImage'
import PhotoGallery from '../components/PhotoGallery'

function PromptBoard() {
  
  
  return (
    <div className="app-section">
      <section className="row my-5">
        <div className="col-sm-12 col-md-8 mx-auto ">
          <div className="card img-g">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-12 col-md-12">
                  <div className="flex mt-1 mb-3">
                    <CreateImage />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-xl-12 mt-5">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6 col-sm-12 mx-auto">

                  <CompletedImageXLList />
                  <div className="notices">
                    <div className="alert alert-outline-primary alert-dismissible fade show" role="alert">
                      ❗️ Image downloads are available for 20 minutes. Remember to save completed images loosing them!
                    </div>
                  </div>
                </div>
              </div>

              <div className="my-3">
                <PhotoGallery />
              </div>

            </div>
          </div>



        </div>

      </section>
    </div>
  )
}

export default PromptBoard