import React from 'react'

function ErrorFallback({ error, resetErrorBoundary }) {

  return (
    <>
      {
         (
          <div id="notification-15" className={"notification-box show"}>
            <div className={`notification-dialog ios-style bg-danger ` }>
              <div className="notification-header">
                <div className="in">
                  <strong> { 'Operation Error'} </strong>
                </div>
                <div className="right">
                  <span></span>
                </div>
              </div>
              <div className="notification-content">
                <div className="in">
                  <h3 className="subtitle">{`Something went wrong `}</h3>
                  <div className="text">{error.message}</div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  )
}

export default ErrorFallback