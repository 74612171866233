import { useState } from 'react'
import { useAppContext } from '../context/app.context'
import { postUserMedia } from '../requests/lagacy-api'
import PhotoItems from './PhotoItems'



export default function PhotoGallery() {

  const { userPhotos, onNewMedia, handleLoading, showNotification } = useAppContext()
  const [selectedPhoto, setSelectedPhoto] = useState<string | null>(null)


  const handleClick = (media: any) => {
    onNewMedia(media)
    setSelectedPhoto(media.url)
  }

  const handlePost = async (media: any) => {
    handleLoading(true)
    const data = await postUserMedia({
      title: "",
      content: "",
      media_url: media.url
    }).catch(err => console.log(err));
    if (!data) return
    if (data && data.post_id) {
      showNotification({
        title: 'Hurray! 🎇',
        message: 'Photo Successfully Posted',
        type: 'bg-success'
      });
    }
    handleLoading(false)
  }

  return (

    <div className="row gap-0 gallery-media">
      {
        userPhotos.length === 0 && (
          <>
            <div className="col">
              <div className="exampleBox"></div>
            </div>
            <div className="col">
              <div className="exampleBox"></div>
            </div>
            <div className="col">
              <div className="exampleBox"></div>
            </div>
            <div className="col">
              <div className="exampleBox"></div>
            </div>
          </>
        )
      }
      {
        userPhotos.map((media, i: number) => (
          <PhotoItems key={i} userPhoto={media} handleClick={handleClick} handlePost={handlePost} selectedPhoto={selectedPhoto} />
        ))
      }
    </div>

  )

}