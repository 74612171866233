import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { authHeader } from '../../lib/utils';
import { WP_URL } from '../../lib/config';

const httpLink = createHttpLink({
  uri: import.meta.env.VITE_APP_GRAPHQL_API_URL || "https://api.tohju.com/graphql",
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const authToken = authHeader();

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: authToken ? `Bearer ${authToken}` : "",
      // "X-Store-Host": WP_URL,
    }
  }
});  





export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});