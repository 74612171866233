import moment from "moment";
import { BsStar, BsForward, BsShare } from "react-icons/bs";
import { FaChevronUp, FaTrash } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { TSession } from "../../lib/types";
import { calendarTime } from "../../lib/utils";
import { FaChevronDown } from "react-icons/fa6";
import { useState } from "react";

const SessionOptions = ({ sessionId, handleSessionDelete, handleSessionShare }) => {
  return (
    <>
      <a
        aria-label="anchor"
        onClick={handleSessionDelete(sessionId)}
        data-bs-toggle="tooltip"
        title=""
        data-bs-original-title="delete"
      >

        <FaTrash className="d-inline" />
        <span className="d-md-inline">&nbsp;Delete</span>
      </a>
      <a
        aria-label="anchor"
        onClick={handleSessionShare(sessionId)}
        data-bs-toggle="tooltip"
        title="share"
      >
        <BsShare className="d-inline" />
        <span className="d-md-inline">&nbsp;Share</span>
      </a>
    </>
  )
}


export function SessionItem({
  session,
  isMine = true,
  handleSessionDelete,
  handleSessionForward,
  handleSessionShare
}: {
  session: TSession
  isMine?: boolean
  handleSessionDelete: (sessionId: string) => (e) => Promise<void>
  handleSessionForward: (sessionId: string) => (e) => Promise<void>
  handleSessionShare: (sessionId: string) => (e) => Promise<void>
}) {
  const isMd = window.innerWidth > 768

  const [showOptions, setShowOptions] = useState(false)

  const partial = str => str.slice(0, 160)


  return (
    <div className="mb-0 session-item  py-2 px-md-3">
      <div className="d-flex align-items-top">

        <div className="d-block d-md-none mr-1 mt-1">
          {
            showOptions ? (
              <button onClick={() => setShowOptions(!showOptions)} className="btn btn-icon p-0">
                <FaChevronUp />
              </button>
            ) : (
              <button onClick={() => setShowOptions(!showOptions)} className="btn btn-icon p-0">
                <FaChevronDown />
              </button>
            )
          }
        </div>
        <div className="d-block w-100">
          <div className="d-flex flex-column flex-md-row justify-content-between session-item__title" >
            <div className="d-flex flex-column pe-1">

              <p className="mb-0 heading ">
                <Link
                  aria-label="anchor"
                  style={{ cursor: 'pointer', textDecoration: 'none', color: 'unset' }}
                  className="d-block"
                  to={isMine ? `/chat/thread/${session.id}` : `/chat/shared/${session.id}`} >
                  {session.props?.title || session.rootSession?.props?.title || 'No Title'}
                </Link>
              </p>
              <p className="subheading">
                {partial(session.props?.summary || session.rootSession?.props?.summary || 'No Messages so far.')}...
              </p>
            </div>
            <div className="icons-mail ps-xs-0 ps-sm-0 ps-md-4 ps-lg-4">

              <div className="mail-date text-muted fw-normal fs-11">
                {calendarTime(session.createdAt)}
              </div>

            </div>
          </div>
        </div>
      </div>
      {
        (showOptions) && (
          <div className="d-flex d-md-none gap-2 gap-md-3 justify-content-start align-items-center session-item__options my-2">
            {
              isMine && (
                <SessionOptions handleSessionDelete={handleSessionDelete} handleSessionShare={handleSessionShare} sessionId={session.id} />
              )
            }
          </div>
        )
      }
      <div className="d-none d-md-flex gap-2 gap-md-3 justify-content-end align-items-center session-item__options my-2 ps-md-0">
        {
          isMine && (
            <SessionOptions handleSessionDelete={handleSessionDelete} handleSessionShare={handleSessionShare} sessionId={session.id} />
          )
        }
      </div>
    </div>
  )
}