import React, { useEffect, useState } from 'react'
import { useSessionStore } from '../../context/session.store'
import { deletePublicShare, getPublicShare, shareSession } from '../../requests/message-req'
import { MdOutlinePublic, MdOutlinePublicOff } from 'react-icons/md'
import { useAgentStore } from '../../context/agent.store'

function Share() {

  const [emailShareUrl, setEmailShareUrl] = useState<string>('')
  const showShare = useSessionStore(state => state.showShare)
  const isSharing = useSessionStore(state => state.isSharing)
  const [accessTo, setAccessTo] = useState<string>('')
  const [publicUrl, setPublicUrl] = useState<string>('')
  const [shareError, setShareError] = useState<boolean>(false)
  const [selectedAgent, setSelectedAgent] = useState<string>("")

  const loadAgents = useAgentStore(state => state.fetchCustomAgents)
  const agents = useAgentStore(state => state.agents)

  useEffect(() => {
    // @todo: load public share url
    getPublicShare(isSharing).then((response) => {
      setPublicUrl(response.sessionUrl)
      setSelectedAgent(response.selectedAgents[0])
    })

    loadAgents()

  }, [])

  const handleShare = async () => {
    setShareError(false)
    if (!isSharing) return
    const response = await shareSession(
      isSharing,
      selectedAgent != '' ? [selectedAgent] : [],
      accessTo
    )
      .catch((error) => {
        console.error(error)
        setShareError(true)
      })
    if (!response) return
    setEmailShareUrl(response.sessionUrl)
  }

  const handlePublicShare = async () => {
    if (!isSharing) return
    const response = await shareSession(
      isSharing, 
      selectedAgent != '' ? [selectedAgent] : []
    )
    setPublicUrl(response.sessionUrl)
  }

  const handlePrivateShare = async () => {
    await deletePublicShare(isSharing)
    setPublicUrl('')
    setSelectedAgent('')

  }

  return (
    <>
      <div className="modal d-block" tabIndex={-1}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Share Session</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => showShare(null)}
              />
            </div>
            <div className="modal-body">
              <p>Invite User by Email.  (⏳️ coming soon)</p>
              <div className="input-group mb-3">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  aria-label="Email"
                  aria-describedby="button-addon2"
                  value={accessTo}
                  readOnly={true}
                  onChange={(e) => setAccessTo(e.target.value)}
                />
                <button
                  className="btn btn-primary"
                  type="button"
                  id="button-addon2"
                  disabled={true}
                  onClick={handleShare}
                >
                  Share
                </button>
              </div>
              {
                emailShareUrl !== '' && (
                  <div className="alert alert-light my-2" role="alert">
                    <a href={emailShareUrl} className="alert-link">{emailShareUrl}</a>
                  </div>
                )
              }
              {
                shareError && (
                  <div className="alert alert-danger my-2" role="alert">
                    <a href="#" className="alert-link">{`Error sharing session`}</a>
                  </div>
                )
              }
              <p>Share Link to Anyone</p>
              <div className="input-group mb-3 flex-column">
                <label className='form-label'>Select Agent for Public Url .</label>

                <select name="" id="" className="form-control w-100" value={selectedAgent} onChange={e => setSelectedAgent(e.target.value)}>
                  <option value="">No Agent Selected</option>
                  {
                    agents && agents.map((agent, key) => (
                      <option key={key} value={agent.id}>{agent.name}</option>
                    ))
                  }
                </select>
              </div>
              <div className="input-group mb-3">
                <button disabled={false} className={`btn ${publicUrl !== '' ? 'btn-dark' : 'btn-outline-dark'}`} type="button" onClick={handlePublicShare}>
                  <MdOutlinePublic className='d-inline' />&nbsp;
                  Public
                </button>
                <button disabled={publicUrl == '' } className="btn btn-outline-danger" type="button" onClick={handlePrivateShare}>
                  <MdOutlinePublicOff className='d-inline' />&nbsp;
                  Hide
                </button>
              </div>
              <div className="input-group mb-3 flex-column">
                <label className='form-label'>Invite User by Public Url.</label>

                <input
                  type="text"
                  className="form-control w-100"
                  placeholder="Not shared publicly yet."
                  aria-label="Link Url"
                  value={publicUrl}
                  readOnly
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => showShare(null)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop'></div>
    </>
  )
}

export default Share