import { create } from 'zustand'
import { produce } from 'immer'
import { getUserMessages, pollMessages, postUserMessages } from '../requests/message-req';
import { TMessage } from "../lib/types";


type TStore = {
  messages: TMessage[]
  isLoading: boolean
  setLoading: (value: boolean) => void
  postMessage: (message: TMessage) => Promise<void>
  getMessage: (onComplete?: () => void) => Promise<void>
  getModelResponse: (breakCondition: (msg: any) => boolean) => Promise<void>
}

function removeDuplicateIds(array) {
  const uniqueIds = new Set();
  const filteredArray = array.filter(item => {
    const isDuplicate = uniqueIds.has(item.id);
    uniqueIds.add(item.id);
    return !isDuplicate
  });
  return filteredArray;
}

function removeNoIds(array) {
  const filteredArray = array.filter(item => {
    
    return item && item.id
  });
  return filteredArray;
}

export const useMessageStore = create<TStore>((set, get) => ({
  messages: [] as TMessage[],
  isLoading: false,

  setLoading: bool => {
    set(
      produce(state => {
        state.isLoading = bool
      })
    )
  },
  getMessage: async (onComplete) => {
    const lastMessage = get().messages[get().messages.length - 1] || {}
    const createdAt = (lastMessage && lastMessage.createdAt) || ''
    get().setLoading(true)
    const messages = await getUserMessages(createdAt).catch(err => console.log(err))
    if (!messages) return
    set(
      produce(state => {
        state.messages = [...state.messages, ...messages]
      })
    )
    get().setLoading(false)
    onComplete && onComplete()
  },

  // Defines an asynchronous function named 'getModelResponse' to poll messages and update the state.
  getModelResponse: async (breakCondition) => {
    // Retrieves the last message from the current state's messages array.
    const lastMessage = get().messages[get().messages.length - 1]
    // Extracts the 'createdAt' timestamp from the last message or assigns an empty string if lastMessage is not available.
    const createdAt = (lastMessage && lastMessage.createdAt) || ''
    // Check if the break condition is satisfied for the last message,
    // if so, return immediately without proceeding further.
    if (breakCondition(lastMessage)) return
    get().setLoading(true)
    // Calls the 'pollMessages' function to continuously fetch new messages based on the creation timestamp of the last message.
    await pollMessages((newMessages) => {
      get().setLoading(false)
      // Takes the last message from the fetched new messages.
      const modelResponse = newMessages[newMessages.length - 1]
      // Checks if the break condition is not satisfied for the newly fetched model response,
      // which allows the continuation of updating the state.
      if (breakCondition(modelResponse)) {
        set(
          produce(state => {
            // Updates the current state by appending the new messages to the existing messages array.
            state.messages = [...state.messages, ...newMessages]
          })
        )
      }
    }, createdAt).catch(err => console.log(err)) // Passes the 'createdAt' timestamp to begin polling from that point forward.
  },

  /**
   * Create a new message, post it to the server, and update the state with the new message.
   * @param message 
   */
  postMessage: async (message: TMessage) => {
    get().setLoading(true)
    const user_message = await postUserMessages(message.content);
    set(
      produce(state => {
        // Append the new message with a created timestamp to the current state.messages array
        state.messages = [...state.messages, { ...user_message }];
      })
    );
    get().setLoading(false)

    // After adding the message, grab the last message as it has the latest createdAt timestamp
    const lastMessage = get().messages[get().messages.length - 1];
    const createdAt = (lastMessage && lastMessage.createdAt) || '';
    
    get().setLoading(true)
    await pollMessages((newMessages) => {

      // Update the state with the filtered list of new messages
      set(
        produce(state => {
          state.messages = removeNoIds( removeDuplicateIds([...state.messages, ...newMessages]) );
        })
      );
      get().setLoading(false)
    }, createdAt);
  }

}))