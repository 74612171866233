import React, { useEffect, useState } from 'react'
import { FaStop } from 'react-icons/fa6'
import { WP_URL } from '../lib/config'
import { useSignIn } from 'react-auth-kit'
import { authWithStore, getAuthUserWithWp, signinWithStore, signinWithWp } from '../requests/fetch'
import { MdOutlineCircle } from "react-icons/md";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function EmailAuth() {
  const signIn = useSignIn()
  // get req params
  const query = useQuery()
  const xslip = query.get("xslip")  
  const navigate = useNavigate()

  const [loginError, setLoginError] = useState<string | null>(null)
  const [loginSuccess, setLoginSuccess] = useState<string | null>(null)
  const [isLoading, setLoading] = useState(false)


  useEffect(() => {

    if (!xslip) return
    async function auth() {
      const response = await authWithStore({xslip}).catch(err => {
        setLoginError(err.response?.data?.data?.message || err.message)
        setLoading(false)
      })

      if (response) {

        const res = response.data

        signIn({
          token: res.jwt,
          expiresIn: 7100,
          tokenType: "Bearer",
          authState: res.user,
          // refreshToken: res.data.refreshToken,                    // Only if you are using refreshToken feature
          // refreshTokenExpireIn: res.data.refreshTokenExpireIn     // Only if you are using refreshToken feature
        })

        // remove sessionId from local storage
        localStorage.removeItem("sessionId")

        navigate("/storenet")

      } else {
        setLoginError("Failed to sign in")
      }
    }

    auth()


  }, [])


  return (
    <>
      <div id="appCapsule">
        <div className="row"> 
          {
            (!xslip || loginError) ? (

              <div className="col-sm-12 col-md-3 mx-auto">
                <div className="section mt-2 text-center">
                  <h1>Signing Failed</h1>
                  <h4>Try another attempt at signing in.</h4>
                </div>
              </div>
            ) : (
              <div className="col-sm-12 col-md-3 mx-auto">
                <div className="section mt-2 text-center">
                  <h1>Signing In</h1>
                  <h4>Please be patient while we sign you in.</h4>
                </div>
                <div className="section mb-5 p-2">
                  {
                    loginSuccess && (
                      <div className="d-flex justify-content-center mt-2">
                        <div className="alert alert-success mb-1" role="alert">
                          {loginSuccess}
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
            )
          }
        </div>
      </div>
    </>

  )
}

export default EmailAuth