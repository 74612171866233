import { TCreateSessionMessageArgs, TSession } from "../../lib/types";
import {
  TAddAgentArgs,
  TAgent,
  TGetConversationArgs,
  TMessage,
} from "../../lib/types";
import { apolloClient } from "./client";
import { CREATE_MESSAGE_REPORT_MUTATION, CREATE_NEW_SESSION_TASK, CREATE_NEW_TEMP_SESSION, DELETE_USER_SESSION, GET_SHARED_SESSIONS, GET_USER_SESSIONS } from "./gql/sessions";
import {
  CREATE_BULK_SESSION_MESSAGE,
  CREATE_NEW_SESSION,
  CREATE_NEW_SESSION_AGENT,
  CREATE_NEW_SESSION_MESSAGE,
  GET_SESSION_AGENTS,
  GET_SESSION_MESSAGES,
} from "./gql/sessions";



export const addSessionAgent = async (agentArgs: TAddAgentArgs) => {
  const response = await apolloClient.mutate({
    mutation: CREATE_NEW_SESSION_AGENT,
    variables: {
      data: agentArgs,
    },
  });
  return response.data.createAgent;
};

export const createSession = async (sessionName: string, agentType?: string) => {
  const response = await apolloClient.mutate({
    mutation: CREATE_NEW_SESSION,
    variables: {
      sessionName,
      agentType
    },
  });

  return response.data.createAgentSession;
};

export const createTempSession = async (linkId: string, rootSessionId: string) => {
  const response = await apolloClient.mutate({
    mutation: CREATE_NEW_TEMP_SESSION,
    variables: {
      rootSessionId,
      linkId
    },
  });

  return response.data.createTempSession;
};

export const fetchSessionAgents = async (sessionId: string) => {
  const response = await apolloClient.query({
    query: GET_SESSION_AGENTS,
    variables: {
      sessionId,
    },
  });

  return response.data.agents as TAgent[];
};

export const getSessionMessages = async (data: TGetConversationArgs) => {
  const response = await apolloClient.query({
    query: GET_SESSION_MESSAGES,
    variables: {
      data,
    },
    fetchPolicy: "no-cache"
  });

  return response.data.messages as TMessage[];
};


export const deleteUserSession = async (sessionId) => {
  await apolloClient.mutate({
    mutation: DELETE_USER_SESSION,
    variables: {
      sessionId
    }
  });

  return true
};

export const getUserSessions = async () => {
  const response = await apolloClient.query({
    query: GET_USER_SESSIONS,
    fetchPolicy: "no-cache"
  });

  return response.data.userSessions as TSession[];
};

export const getSharedSessions = async () => {
  const response = await apolloClient.query({
    query: GET_SHARED_SESSIONS,
    fetchPolicy: "no-cache"
  });

  return response.data.sharedSessions as TSession[];
};

export const createSessionMessage = async (data: TCreateSessionMessageArgs) => {
  const response = await apolloClient.mutate({
    mutation: CREATE_NEW_SESSION_MESSAGE,
    variables: {
      data,
    },
  });

  return response.data.createMessage;
};

export const createSessionTask = async (data: TCreateSessionMessageArgs) => {
  const response = await apolloClient.mutate({
    mutation: CREATE_NEW_SESSION_TASK,
    variables: {
      data,
    },
  });

  return response.data.createTask;
};

export const createBulkSessionMessage = async (
  data: TCreateSessionMessageArgs
) => {
  const response = await apolloClient.mutate({
    mutation: CREATE_BULK_SESSION_MESSAGE,
    variables: {
      data,
    },
  });

  return response.data.createBulkMessage;
};

// Function to send the request using the Apollo Client
export function sendMessageReport(content, messageId) {
  return apolloClient.mutate({
    mutation: CREATE_MESSAGE_REPORT_MUTATION,
    variables: { content, messageId },
  })
  .then(response => {
    // Handle the response here
    console.log('Message report created:', response.data.createMessageReport);
    return response.data.createMessageReport;
  })
  .catch(error => {
    // Handle any errors here
    console.error('Error creating message report:', error);
  });
}

