import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useAppContext } from '../context/app.context'
import { uploadPhoto } from '../requests/fetch'

function Text2Img() {
  const [isLoading, setLoading] = useState(false)
  const [isAwaiting, setAwaiting] = useState(false)
  const [mediaUrl, setMediaUrl] = useState("")

  const { showNotification, handleImg2Txt, currentPrompt } = useAppContext()
  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0]

    setLoading(true)
    setMediaUrl('')
    const mediaUrl = await uploadPhoto(file).catch(err => console.log(err));
    if (!mediaUrl) return
    showNotification({
      title: 'Upload done...',
      message: 'Photo Successfully Uploaded',
      type: 'bg-success'
    });

    setLoading(false)
    setMediaUrl(mediaUrl)

  }, [showNotification])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const handleGetDescription = useCallback(async (mediaUrl) => {
    setLoading(true)
    await handleImg2Txt(mediaUrl).catch(err => console.log(err))
    setLoading(false)
    setAwaiting(true)
    setMediaUrl('')
  }, [handleImg2Txt])



  return (
    <>
      <div className="flex flex-col mt-3">
        <div {...getRootProps()} className="custom-file-upload p-3" id="fileUpload1">
          <input {...getInputProps()} accept=".png, .jpg, .jpeg" />
          <div className="d-flex flex-column justify-content-center w-100">
            <div className="mt-2">
              {
                isDragActive ?
                  <p className='text-center'>Drop the files here ...</p> :
                  <p className='text-center'>Drag 'n' drop an image here to generate a description.</p>
              }
            </div>
            {
              !isDragActive && (
                <p className="text-center">
                  <i>Click to select a photo</i>
                </p>
              )
            }
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-2">
        <div className="form-group">
          <div className="input-wrapper mt-3 d-flex justify-content-end gap-3">
            <div className="col-loading">
              {
                isLoading && (
                  <div className="spinner-border text-primary" role="status" />
                )
              }
            </div>
            {
              mediaUrl !== '' && (
                <button disabled={mediaUrl !== ''} className="btn btn-link btn-sm">
                  Photo Uploaded
                </button>
              )
            }
            <button disabled={mediaUrl === '' || isLoading} onClick={() => handleGetDescription(mediaUrl)} className="btn btn-primary ">
              Describe Photo
            </button>
          </div>
        </div>
      </div>
      {
        isAwaiting && currentPrompt.prompt !== '' && (
          <div className="flex flex-col mt-3">
            <div className="p-3">
              <div className="d-flex flex-column justify-content-center w-100">
                <div className="mt-2">
                  <p className='text-center'>
                    {currentPrompt.prompt}
                  </p> 
                </div>
                <button onClick={() => setAwaiting(false)} className="btn btn-link">
                  Done
                </button>
              </div>
            </div>
          </div>
        )
      }
    </>
  )
}

export default Text2Img