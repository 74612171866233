import React, { useState } from 'react'
import { FaStop } from 'react-icons/fa6'
import { WP_URL } from '../lib/config'
import { useSignIn } from 'react-auth-kit'
import { getAuthUserWithWp, signinWithWp } from '../requests/fetch'
import { MdOutlineCircle } from "react-icons/md";
import { Link, useNavigate } from 'react-router-dom'



function LoginPage() {
  const signIn = useSignIn()
  const navigate = useNavigate()

  const [form, setForm] = useState({
    username: "",
    password: ""
  })
  const [loginError, setLoginError] = useState(null)
  const [isLoading, setLoading] = useState(false)

  const handleSubmit = async (e) => {
    setLoading(true)
    setLoginError(null)
    e.preventDefault()
    const tokenResponse = await signinWithWp(form).catch(err => {
      setLoginError(err.response?.data?.message || err.message)
      setLoading(false)
    })
    if (!tokenResponse) return
    const token = tokenResponse?.data.jwt
    const user = tokenResponse?.data.user

    signIn({
      token,
      expiresIn: 7100,
      tokenType: "Bearer",
      authState: user,
      // refreshToken: res.data.refreshToken,                    // Only if you are using refreshToken feature
      // refreshTokenExpireIn: res.data.refreshTokenExpireIn     // Only if you are using refreshToken feature
    })
    window.location.replace("/chat")

    setLoading(false)
    
  }


  return (
    <>
      <div id="appCapsule">
        <div className="row">
          <div className="col-sm-12 col-md-3 mx-auto">
            <div className="section mt-2 text-center">
              <h1>Log in</h1>
              <h4>Fill the form to log in</h4>
            </div>
            <div className="section mb-5 p-2">
              <form onSubmit={handleSubmit}>
                <div className="card">
                  <div className="card-body pb-1">
                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="email1">
                          Username/Email
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="email1"
                          placeholder="Your username or email"
                          value={form.username}
                          onChange={e => setForm({ ...form, username: e.target.value })}
                        />
                        <i className="clear-input">
                          <FaStop />
                        </i>
                      </div>
                    </div>
                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="password1">
                          Password
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="password1"
                          autoComplete="off"
                          placeholder="Your password"
                          value={form.password}
                          onChange={e => setForm({ ...form, password: e.target.value })}
                        />
                        <i className="clear-input">
                          <FaStop />
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-links mt-2">
                  <div>
                    <Link to="/register" >Register Now</Link>
                  </div>
                  <div>
                    <a href={`${WP_URL}/register `} className="text-muted" target='_blank' rel="noreferrer" >
                      Forgot Password?
                    </a>
                  </div>
                </div>
                {
                  loginError && (
                    <div className="d-flex justify-content-center mt-2">
                      <div className="alert alert-danger mb-1" role="alert">
                        {loginError || 'Login attempt failed. Please try again.'}
                      </div>
                    </div>
                  )
                }
                <div className="form-button mt-3  transparent">
                  <button disabled={isLoading} type="submit" className="btn btn-primary btn-block btn-lg">
                    Log in &nbsp;
                    {
                      isLoading && (

                        <span className='animate__animated animate__heartBeat animate__infinite'>
                          <MdOutlineCircle />
                        </span>
                      )
                    }
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}

export default LoginPage