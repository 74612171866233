/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { fetchSessionAgents } from '../../requests/graphql/session'
import { useThreadStore } from '../../context/thread.store'
import { useAgentStore } from '../../context/agent.store'
import Thread from './Thread'
import { useSignIn, useAuthUser } from 'react-auth-kit'
import { useAppContext } from '../../context/app.context'
import { useSessionStore } from '../../context/session.store'
import useSocket from '../../hooks/useSocket'
import { TAppUser } from '../../lib/types'
import { getWpSessionUser } from '../../requests/fetch'
import { getMySharedSession, getShareSession } from '../../requests/message-req'

/**
 * Private shared sessions
* @returns 
 */
function SharedBox() {

  const { handleMenu } = useAppContext();
  const { hashedId: linkId, rootSessionId } = useParams()
  const [thread, setThread] = useState({
    rootId: "",
  })

  const sessionId = useSessionStore(state => state.sessionId)
  const setSessionId = useSessionStore(state => state.setSessionId)
  const reload = useThreadStore(state => state.reloadSessionMessages)
  const getSessionAgents = useThreadStore(state => state.getSessionAgents)
  const loadAgents = useAgentStore(state => state.fetchCustomAgents)

  const webSocketClient = useSocket()

  const rootId = thread.rootId

  useEffect(() => {
    if (!sessionId || sessionId === "") {
      return
    }
    if (!rootId || rootId === "") {
      return
    }
    // should use the thread id to get the messages
    reload({
      after: "",
      sessionId: sessionId!,
    })

    getSessionAgents(sessionId!)

    getSessionAgents(rootId!)
    
    loadAgents()

    return () => {
      webSocketClient.logOut()
    }

  }, [sessionId, rootId])


  useEffect(() => {
    if (linkId) {
      // remove this. it should fetch the 
      getShareSession(linkId)
      .then(({rootId, sessionId}) => {
        setThread({ rootId})
        setSessionId(sessionId)
      })
    }

    if (rootSessionId) {
      setSessionId(rootSessionId)
      getMySharedSession(rootSessionId)
      .then(({rootId}) => {
        setThread({ rootId})
      })
    }
  }, [linkId])

  useEffect(() => {
    return () => {
      webSocketClient.logOut();
    };
  }, [])

  if (!sessionId) {
    // Render a loading state or null while there is no session ID
    return <div>Loading session...</div>;
  }
  
  return <Thread isShared={true} />
}

export default SharedBox