/* eslint-disable no-restricted-globals */

import { useAuthUser, useIsAuthenticated } from 'react-auth-kit';
import PrivateRouter from './routers/priv';
import PublicRouter from './routers/public';



export default function MainRouter() {

  const isAuthenticated = useIsAuthenticated()
  const user = useAuthUser()()

  return (
    <>

      {
        isAuthenticated() && !user?.user_email.includes('woo_gpt_user') ? (

          <PrivateRouter />
        ) : (
          <PublicRouter />
        )
      }


    </>
  )


}