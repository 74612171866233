import React, { useCallback, useMemo, useState } from 'react'
import { TAgent } from '../../lib/types'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { MdClose } from 'react-icons/md';

import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { nord } from 'react-syntax-highlighter/src/styles/prism'

import 'swiper/css';
import 'swiper/css/pagination';
import { useAgentStore } from '../../context/agent.store';
import { IoCheckmarkCircle } from 'react-icons/io5';
import { BsLightningCharge } from 'react-icons/bs';
import { reloadAgentToolData, suggestPrompts } from '../../requests/agents-req';
import { IoReload } from "react-icons/io5";


// import required modules

function PromptItem({ prompt }: {
  prompt: {
    description: string;
    filePath: string;
    title: string;
    fileContent: string;
  }
}) {
  const [show, setShow] = useState(false)

  const extractPrompt = (text: string) => {
    return text.includes('# Prompt') ? text.split('# Prompt')[1] : text
  }

  return (
    <div className="col-12 col-md-5 col-sm-12 col-xs-12">
      <div className="card p-3 p-md-4">

        <h6 className="bold mb-1" id="head1">Prompt</h6>
        <h2 className="bold mb-3" id="head2">{prompt.title}</h2>

        <p>{prompt.description}</p>

        {
          show && (
            <Markdown
              remarkPlugins={[remarkGfm]}
              components={{
                code(props) {
                  const { children, className, node, ...rest } = props
                  const match = /language-(\w+)/.exec(className || '')
                  return match ? (
                    <SyntaxHighlighter
                      {...rest}
                      PreTag="div"
                      children={String(children).replace(/\n$/, '')}
                      language={match[1]}
                      style={nord}
                    />
                  ) : (
                    <code {...rest} className={className}>
                      {children}
                    </code>
                  )
                }
              }}
            >
              {extractPrompt(prompt.fileContent)}
            </Markdown>

          )
        }

        <button onClick={() => setShow(!show)} className="btn text-black btn-sm col-6 col-md-4 p-2 px-0 py-md-2"> {show ? 'Hide' : 'Show'} Prompt</button>

      </div>
    </div>
  )
}

function PromptCard({ handleClick, card }) {

  const isMd = window.innerWidth > 768
  const [isLoading, setLoading] = useState(false)

  const handleShowAgentPrompts = async (e, card) => {
    e.stopPropagation()
    setLoading(true)
    await handleClick(card).catch(() => setLoading(false))
    setLoading(false)

  }

  const handleReloadPromptData = async (e, agent) => {
    e.stopPropagation()
    setLoading(true)
    await reloadAgentToolData(agent).catch(() => setLoading(false))
    setLoading(false)

  }

  return (
    <div className="card-body">
      <h5 style={{ width: isMd ? '150px' : '120px' }} className="card-title truncate">{card.name}</h5>
      <p style={{ width: isMd ? '150px' : '120px' }} className="card-text">{card.props ? card.props.goal : card.id}</p>
      <div className="d-flex justify-content-between flex-row text-right p-1 mb-0 hidden">
        <button className="btn btn-link" onClick={e => handleShowAgentPrompts(e, card)}>
          <BsLightningCharge /> Prompts
        </button>
        <button className="btn btn-link" onClick={e => handleReloadPromptData(e, card)}>
        <IoReload /> Reload Data
        </button>
        {
          isLoading && (
            <div className="spinner-border text-primary" role="status" />
          )
        }
      </div>
    </div>
  )
}

function SelectAgent({ cards, handleClick, onClose }: {
  onClose: () => void,
  cards: TAgent[],
  handleClick: (agent: TAgent) => any
}) {

  const isMd = window.innerWidth > 768
  const selectedAgents = useAgentStore(state => state.selectedAgents)
  const setAgentPrompts = useAgentStore(state => state.setAgentPrompts)
  const agentPrompts = useAgentStore(state => state.agentPrompts)

  const active = useCallback((card) => {
    return selectedAgents.map(agent => agent.id).includes(card.id)

  }, [selectedAgents])

  const handleShowAgentPrompts = async (card) => {
    setAgentPrompts(null)
    const prompts = await suggestPrompts(card.props)
    if (prompts) {
      setAgentPrompts(prompts)
    }
  }

  if (!cards.length) {
    return (<></>)
  }

  return (
    <div className="agentFooter">
      <div className="container-fluid">
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex flex-nowrap overflow-scroll mt-2">
            <Swiper
              slidesPerView={"auto"}
              spaceBetween={20}
              width={270}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20
                },
                // when window width is >= 480px
                480: {
                  slidesPerView: 1,
                  spaceBetween: 30
                },
                // when window width is >= 640px
                640: {
                  slidesPerView: 2,
                  spaceBetween: 40
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 40
                },
              }}
            >
              {cards.map((card, index) => (
                <SwiperSlide key={index}>
                  <div className={`card ${active(card) ? 'active' : ''} w-100`} onClick={() => handleClick(card)} >
                    {
                      active(card) && (
                        <span className='absolute isSelected' >
                          <IoCheckmarkCircle color='green' />
                        </span>
                      )
                    }
                    <PromptCard card={card} handleClick={handleShowAgentPrompts} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>

          </div>
          <div className="col-auto">
            <div className="d-flex align-items-center h-100">
              <button className="btn btn-icon btn-outline-danger p-1 ms-2" onClick={() => (setAgentPrompts(null), onClose())}>
                <MdClose color='red' />
              </button>
            </div>
          </div>
        </div>
        {
          agentPrompts && (
            <div style={{ "height": "60vh", overflowY: "scroll" }} className="mt-3 d-flex flex-column gap-3 justify-content-between">
              {
                agentPrompts?.map((prompt, index) => (
                  <div className="row justify-content-center" key={index}>
                    <PromptItem prompt={prompt} />
                  </div>
                ))
              }
              <div>
              </div>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default SelectAgent