
import React, { useEffect } from 'react'
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom'
import PlatformHeader from '../components/PlatformHeader';
import Thread from '../components/session/Thread';
import ConnectShopAssistantPage from '../pages/ConnectShopAssistant';
import ConnectStoreManagerPage from '../pages/ConnectStoreManager';
import EmailAuth from '../pages/EmailAuth';
import ErrorPage from '../pages/ErrorPage';
import LoginPage from '../pages/LoginPage';
import RegisterPage from '../pages/RegisterPage';
import StoreLogin from '../pages/StoreLogin';
import { ApolloProvider } from '@apollo/client';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../components/ErrorFallback';
import AppProvider from '../context/app.context';
import PublicChat from '../pages/PublicChat';
import { apolloClient } from '../requests/graphql/client';


function PublicApp() {
  useEffect(() => {
    async function initSentry() {
      await import("../sentry");
    }

    initSentry();
  }, []);


  return (
    <ApolloProvider client={apolloClient}>
      <AppProvider>
        <ErrorBoundary FallbackComponent={ErrorFallback}  >
          <PublicChat />
        </ErrorBoundary>
      </AppProvider>
    </ApolloProvider>
  )
}



function PublicRouter() {

  const publicRouter = createBrowserRouter([
    {
      path: "/",
      element: <LoginPage />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <LoginPage />,
        },
      ]

    },
    {
      path: "chat",
      element: <PublicApp />,
      children: [
        {
          path: "shared/thread/:hashedId",
          element: <Thread isShared={true} />,
        },
      ]
    },
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/emailauth",
      element: <EmailAuth />,
    },
    {
      path: "/storelogin",
      element: <StoreLogin />,
    },
    {
      path: "/register",
      element: <RegisterPage />,
    },
    {
      path: "/connect-store",
      element: (
        <>
          <PlatformHeader />
          <div className="main-container container mx-auto">
            <Outlet />
          </div>
        </>
      ),
      children: [
        {
          path: "",
          element: <ConnectStoreManagerPage />,
        },
        {
          path: "customer",
          element: <ConnectShopAssistantPage />,
        },
        {
          path: "shop_manager",
          element: <ConnectStoreManagerPage />,
        },
      ]
    },
    {
      path: "/*",
      element: <LoginPage />,
    },
  ]);


  return (
    <RouterProvider router={publicRouter} />
  )
}

export default PublicRouter