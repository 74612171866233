import React from 'react';

const ModalBackdrop = ({handleClick}) => {
    return (
        <div onClick={handleClick} className="modal-backdrop" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.5)' }}>
            {/* Your modal content here */}
        </div>
    );
}

export default ModalBackdrop;