"use client";

import { StrictMode } from 'react';
import { render } from 'react-dom';


import './index.scss';
import 'animate.css';


import MainRouter from './MainRouter';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from 'react-auth-kit'

import './context/devtools';

render(
  <StrictMode>
    
      <AuthProvider
        authType={"localstorage"}
        authName={"_auth"}
        cookieDomain={window.location.hostname}
        cookieSecure={window.location.protocol === "https:"}>
        <MainRouter />
      </AuthProvider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
