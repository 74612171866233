import React, { useState } from 'react'
import { useAppContext } from '../context/app.context'

function PromptImage({ handleClick, isLoading}) {
  const { userIsReady } = useAppContext()

  const [form, setForm] = useState({
    "prompt": "",
    "negative_prompt": ""
  })

  return (
    <div className="flex flex-col items-start justify-center form-group">
      <div className="form-group boxed">
        <div className="input-wrapper">
          <label className="label" htmlFor="desc">Description</label>
          <textarea value={form.prompt} onChange={(e) => setForm(prev => ({ ...prev, prompt: e.target.value }))} id="desc" rows={4} className="form-control" placeholder="A cute mouse pilot wearing aviator goggles, unreal engine render, 8k"></textarea>
        </div>
      </div>
      <div className="form-group">
        <div className="input-wrapper">
          <label className="label" htmlFor="negative_desc">Negative Description</label>
          <textarea value={form.negative_prompt} onChange={(e) => setForm(prev => ({ ...prev, negative_prompt: e.target.value }))} id="negative_desc" rows={4} className="form-control" placeholder="No blue colors, no ugly faces"></textarea>
        </div>
      </div>
      <div className="form-group">
        <div className="input-wrapper mt-3 d-flex justify-content-end gap-3">
          <div className="col-loading">
            {
              isLoading && (
                <div className="spinner-border text-primary" role="status" />
              )
            }
          </div>
          <button disabled={!userIsReady || form.prompt === ''} onClick={() => handleClick(form)} className="btn btn-primary ">
            Generate
          </button>
        </div>
      </div>
    </div>
  )
}

export default PromptImage